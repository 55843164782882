import styled from "styled-components";
import { BREAK_POINT } from "./common/windowSize";

export const Section5Wrap = styled.div`
  position: relative;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Section5Body = styled.div`
  padding: 162px 0;
  width: calc(100% - 640px);
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  @media (max-width: ${BREAK_POINT.section5.bp_first}px) {
    width: calc(100% - 320px);
    font-size: 20px;
  }
  @media (max-width: ${BREAK_POINT.section5.bp_second}px) {
    width: calc(100% - 160px);
    font-size: 18px;
  }
  @media (max-width: ${BREAK_POINT.section5.bp_third}px) {
    width: calc(100% - 80px);
    font-size: 15px;
  }
`;

export const HeaderTitle = styled.p`
  font-size: 2em;
  line-height: 75px;
  font-weight: 700;
  letter-spacing: -2.5px;
  margin: 0px 0px 30px 0;
`;
export const SummaryText = styled.span`
  line-height: 50px;
  font-weight: bold;
  letter-spacing: -1.25px;
  font-size: 1em;
  color: #626262;
`;

export const ContactInfo = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0 19px;
`;
export const ContactValue = styled(SummaryText)`
  color: #6a6a6a;
`;

export const FormGroup = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
`;

export const FormWrapFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0 40px;
`;

export const FormLabel = styled.label`
  font-size: 1em;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: -1.25px;
  margin-bottom: 11px;
`;

export const FormInput = styled.input`
  border: none;
  padding: 22px 0;
  border-bottom: 1px solid #5b5b5b;
  background-color: transparent;
  width: 100%;
  font-size: 1em;
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-size: 1em;
    color: #a7a7a7;
    letter-spacing: -1.25px;
  }
`;
export const FormError = styled.p`
  margin: 5px 0;
`;

export const SendButton = styled.button`
  margin-top: 60px;
  padding: 23px 0;
  max-width: 278px;
  width: 278px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #707070;
  background-color: white;
  font-size: 25px;
  letter-spacing: 50px;
  font-weight: 700;
  letter-spacing: -1.25px;
`;

//mobile
export const S5BgContent = styled.div`
  width: 100%;
  /* height: 353px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px 0;
  background-image: url(${(props) => props.$bg});
  background-size: cover;
  background-position: center;
  font-size: 13.5px;
`;

export const S5Button = styled.button`
  border: none;
  padding: 24px 64px;
  border-radius: 50px;
  color: white;
  background-color: ${(props) => props.$bgColor};
  font-size: 2.4em;
  line-height: 40px;
  letter-spacing: -1.5px;
  font-weight: 700;
  position: relative;
  overflow: hidden;

  > span {
    &.untoggle {
      display: block;
      transform: translateX(${(props) => (props.$toggle ? "-200%" : "0")});
      transition: transform 0.3s ease-in-out;
    }
    &.toggle {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      transform: translateX(${(props) => (props.$toggle ? "0" : "200%")});
      transition: transform 0.3s ease-in-out;
    }
  }
`;
