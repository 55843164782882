import { useEffect, useState } from "react";
import NextD1 from "../../assets/img/next_ico_01.png";
import NextD2 from "../../assets/img/next_ico_02.png";
/**
 * 게시판 페이징 components
 * @param {*} param0 curPage : 현재 페이지 , totalElement : 전체 데이터 수, take: 한 페이지에 보여지는 데이터 수 (default 10)
 * @returns
 */
export const Paging = ({ curPage, totalElement, take, changePage }) => {
  const pageBlockLimit = 10; // 페이지 블럭 제한 (1~10 까지 한 화면에 노출, 11 ~ 20 까지 한 화면에 노출)
  const [pageInfo, setPageInfo] = useState({
    isPrev: false,
    isNext: false,
    isPrevB: false,
    isNextB: false,
    pageStartNum: 1,
    pageEndNum: 1,
    totalPageBlock: 1,
    totalPage: 1,
  });
  const [curPageList, setCurPageList] = useState([]);

  // 이번 블럭 시작 페이지 및 종료 페이지 구하기
  const getCurPageStartAndEnd = () => {};

  const getTotalPage = (totalElement, take) => {
    return Math.floor(totalElement / take) + 1;
  };

  const genCurPageList = (curBlock, totalElement) => {
    let returnList = [];
    let pageArray = [...Array(10)].map((e, i) => i);
    for (var i = 0; i < pageArray.length; i++) {
      const pNum = i + 1;
      if (pNum > pageInfo.totalPage) break;
      returnList.push(pNum + curBlock * 10);
    }

    setCurPageList([...returnList]);
  };

  const genPageInfo = (curPage, totalElement, take) => {
    let curTotalPage = getTotalPage(totalElement, take);
    let isPrev = Math.floor(curPage / 10) != 0;
    let isPrevB = Math.floor(curPage / 10) != 0;
    let isNext = curTotalPage > 10;
    let isNextB = curTotalPage > 10;
    let pageSNum = 1;
    let pageENum = 1;
    let returnList = [];
    let pageArray = [...Array(10)].map((e, i) => i);
    for (var i = 0; i < pageArray.length; i++) {
      const pNum = i + 1;
      if (i === 0) {
        pageSNum = pNum;
      }
      if (pNum > curTotalPage) {
        pageENum = pNum;
        break;
      }
      if (i === pageArray.length - 1) {
        pageENum = pNum;
      }
      returnList.push(pNum + Math.floor(curPage / pageBlockLimit) * 10);
    }
    console.log(curTotalPage);
    console.log(pageENum);
    isNextB = pageENum - 1 !== curTotalPage;
    isNext = pageENum - 1 !== curTotalPage;

    setPageInfo({
      ...pageInfo,
      isPrev,
      isNext,
      isPrevB,
      isNextB,
      pageStartNum: pageSNum,
      pageEndNum: pageENum,
      totalPage: curTotalPage,
    });

    setCurPageList([...returnList]);
  };

  useEffect(() => {
    genPageInfo(curPage, totalElement, take);
    // let curTotalPage = getTotalPage(totalElement, take);
    // setPageInfo({ ...pageInfo, totalPage: curTotalPage });
    // genCurPageList(Math.floor(curPage / pageBlockLimit), totalElement);
  }, [curPage, totalElement]);

  return (
    <div className="contents_list_paging">
      <div className="list_paging_btn_group">
        {pageInfo.isPrev && (
          <button type="button" className={"list_paging_btn left"}>
            <img src={NextD2} />
          </button>
        )}
        {pageInfo.isPrevB && (
          <button type="button" className={"list_paging_btn left"}>
            <img src={NextD1} />
          </button>
        )}
      </div>
      <ul className="list_paging_number_group">
        {curPageList.length > 0 &&
          curPageList.map((d, i) => (
            <li
              key={`p_${d}`}
              className={`list_paging_number ${d == curPage ? "active" : ""}`}
              onClick={() => changePage(d)}
            >
              {d}
            </li>
          ))}
      </ul>

      <div className="list_paging_btn_group">
        {pageInfo.isNext && (
          <button type="button" className={"list_paging_btn right"}>
            <img src={NextD1} />
          </button>
        )}
        {pageInfo.isNextB && (
          <button type="button" className={"list_paging_btn right"}>
            <img src={NextD2} />
          </button>
        )}
      </div>
    </div>
  );
};
