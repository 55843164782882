import { SectionSubText, SectionText } from "./section3Style";
import {
  BannerItem,
  BannerItemWrap,
  Section4Banner,
  Section4Wrap,
} from "./section4Style";
import bg from "../assets/img/15.png";
import { useEffect, useRef, useState } from "react";
import img5 from "../assets/img/jejugolf_bottom_img.jpg";
const Section4 = ({ curIndex }) => {
  const INFO_MESSAGE = "4월 중 오픈입니다";
  const topRef = useRef(null);
  const [gToggle, setGToggle] = useState(false);
  const [aToggle, setAToggle] = useState(false);
  useEffect(() => {
    if (curIndex === 2 && topRef.current != null) {
      topRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [curIndex]);
  return (
    <Section4Wrap ref={topRef}>
      <SectionSubText style={{ margin: "6px 0" }}>JOIN WITH US</SectionSubText>
      <SectionText style={{ margin: "6px 0" }}>
        즐거운 골프의 시작,
        <br />
        <b style={{ fontWeight: "700" }}>제주골프</b>와 함께!
      </SectionText>
      <SectionSubText style={{ margin: "6px 0" }}>
        지금 시작하세요!
      </SectionSubText>
      <Section4Banner style={{ background: "#e9e8ed" }}>
        <img
          src={img5}
          alt={"제주골프 앱 출시 bottom"}
          style={{
            display: "block",
            width: "50%",
            boxShadow: "0px 0px 20px 5px #00000020",
          }}
        />
        {/* <BannerItemWrap>
          <BannerItem
            type="button"
            $bgColor={"#006F49"}
            $toggle={gToggle}
            onMouseEnter={() => !gToggle && setGToggle(true)}
            onMouseLeave={() => gToggle && setGToggle(false)}
          >
            <span className={"untoggle"}>Google Play</span>
            <span className={"toggle"}>{INFO_MESSAGE}</span>
          </BannerItem>
          <BannerItem
            type="button"
            $bgColor={"#FFBA00"}
            $toggle={aToggle}
            onMouseEnter={() => !aToggle && setAToggle(true)}
            onMouseLeave={() => aToggle && setAToggle(false)}
          >
            <span className={"untoggle"}>App Store</span>
            <span className={"toggle"}>{INFO_MESSAGE}</span>
          </BannerItem>
        </BannerItemWrap> */}
      </Section4Banner>
    </Section4Wrap>
  );
};

export default Section4;
