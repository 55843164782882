import styled from "styled-components";

export const FWrap = styled.div`
  padding: 40px 0;
  position: relative;
  background-color: #f6f6f6;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-size: 13.5px;
`;

export const F_OFWrap = styled.div`
  padding: 13.5px 0;
  position: relative;
  font-family: "NotoSansKR_C";
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13.5px;
`;
export const F_OFContents = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const FOfList = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 0 10px;
  width: 100%;
`;
export const FOfListItem = styled.li`
  font-size: 0.52em;
  line-height: 18px;
  letter-spacing: -0.165px;
`;

export const FCol = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
`;

export const FInfoContents = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding-bottom: 30px;
`;

export const FTitle = styled.span`
  font-size: 2em;
  line-height: 50px;
  letter-spacing: -1.25px;
  font-weight: 700;
`;

export const FSubTitle = styled.span`
  font-size: 1em;
  line-height: 25px;
  letter-spacing: -0.625px;
  color: #626262;
  font-weight: bold;
`;

export const FInputGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 10px 0;
  width: 100%;
  padding-bottom: 20px;
`;

export const FInputLabel = styled.label`
  font-size: 1em;
  line-height: 25px;
  font-weight: 700;
  letter-spacing: -0.675px;
`;

export const FInputText = styled.input`
  border: none;
  width: 100%;
  border-bottom: 1px solid #5b5b5b;
  padding-bottom: 10px;
  background-color: transparent;
  outline: none;
  font-size: 1em;
  line-height: 25px;
  letter-spacing: -0.625px;
  color: #a7a7a7;
`;

export const FSendBtnArea = styled.div`
  padding-top: 20px;
  display: flex;
  width: 100%;
  justify-content: end;
`;

export const FSendButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 44px;
  background-color: white;
  border: 1px solid #707070;
  font-weight: 700;
`;

export const FInfoList = styled.div`
  padding-top: 40px;
`;
export const FInfoListItem = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0 10px;
`;

export const FInfoListItemSpan = styled.span`
  font-size: 1em;
  line-height: 25px;
  letter-spacing: -0.625px;
  color: #6a6a6a;
  font-weight: bold;
`;

export const CopyrightMobile = styled.p`
  text-align: right;
  font-size: 0.52em;
  letter-spacing: -0.165px;
  color: #00000057;
`;
