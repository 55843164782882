import styled from "styled-components";
import { BREAK_POINT } from "./common/windowSize";

export const Section4Wrap = styled.div`
  padding-top: 121px;
  text-align: center;
`;

export const Section4Banner = styled.div`
  margin-top: 56px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 706px; */
  width: 100%;
  background-image: url(${(props) => props.$image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const BannerItemWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 720px);
  @media (max-width: ${BREAK_POINT.section4.bp_first}px) {
    font-size: 20px;
  }
  @media (max-width: ${BREAK_POINT.section4.bp_second}px) {
    flex-direction: column;
    gap: 10px 0;
    font-size: 15px;
  }
`;

export const BannerItem = styled.button`
  position: relative;
  padding: 60px;
  border: none;
  width: calc((100% - 62px) / 2);
  min-width: 310px;
  border-radius: 101px;
  color: white;
  background-color: ${(props) => props.$bgColor};
  font-size: 2.4em;
  line-height: 86px;
  font-weight: 700;
  letter-spacing: -3px;
  overflow: hidden;

  .untoggle {
    display: block;
    opacity: 1;
    transform: translateX(${(props) => (props.$toggle ? "-200%" : "0")});
    transition: transform 0.3s ease-in-out;
  }
  .toggle {
    position: absolute;
    opacity: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(${(props) => (props.$toggle ? "0" : "200%")});
    transition: transform 0.3s ease-in-out;
  }
`;
