import { BoardWrap } from "../component/board/boardStyle";
import HelmetMeta from "../utils/commonHelmet";

import { useNavigate } from "react-router-dom";

const EventPage = () => {
  const navi = useNavigate();

  return (
    <>
      <HelmetMeta />
      <BoardWrap>
        <div className="board_info_area">
          <p className="board_info_title">Event</p>
          <p className="board_info_summary">
            <small>제주골프의 이벤트 소식을 알려드립니다.</small>
          </p>
        </div>
        <div className="board_g_body">
          <ul>
            {[...new Array(9)].map((e, i) => (
              <li className={"board_g_item"} key={`board_g_${i}`}>
                <div className={"board_g_wrap"}>
                  <div className={"board_g_item_image"}></div>
                  <div className={"board_g_item_info"}>
                    <p className={"board_g_item_text bgi_type"}>
                      {i === 4
                        ? "evensdfsdfsdfdtTypedfsfsdfsdfsdfsdfsdfsdfsdf"
                        : "test"}
                    </p>
                    <p className={"board_g_item_text bgi_title"}>
                      {i !== 4
                        ? "event title"
                        : "event titleevent titleevent titleevent titleevent titleevent titleevent titleevent titleevent titleevent title"}
                    </p>
                    <span className={"board_g_item_text bgi_date"}>
                      created At
                    </span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </BoardWrap>
    </>
  );
};

export default EventPage;
