import { BoardWrap, SufixIcon } from "../component/board/boardStyle";
import SearchImg from "../assets/img/search_ico.png";
import NextD1 from "../assets/img/next_ico_01.png";
import NextD2 from "../assets/img/next_ico_02.png";
import { useNavigate } from "react-router-dom";
import { Suspense, useEffect, useState } from "react";
import BoardList from "../component/board/boardList";
import fetchData from "../api/board/fetchData";
import BoardLoader from "../component/board/boardLoader";

const BoardPage = () => {
  const navigate = useNavigate();
  const onPageMove = (id) => {
    navigate(`/board/view/${id}`);
  };
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  let callApi = fetchData({ page, keyword }, 0);

  return (
    <BoardWrap>
      <div className="board_info_area">
        <p className="board_info_title">게시판</p>
        <p className="board_info_summary">
          <small>제주골프의 새로운 소식을 알려드립니다.</small>
        </p>
      </div>
      <Suspense fallback={<BoardLoader />}>
        <BoardList
          resourse={callApi}
          setPage={setPage}
          setKeyword={setKeyword}
          onMovePage={onPageMove}
        />
      </Suspense>
    </BoardWrap>
  );
};

export default BoardPage;
