import { S3ContentSubText, S3ContentTitle } from "./section3MobileStyle";
import { S4Wrap } from "./section4MobileStyle";

const Section4Mobile = ({}) => {
  return (
    <S4Wrap>
      <S3ContentSubText>JOIN WITH US</S3ContentSubText>
      <S3ContentTitle>
        즐거운 골프의 시작,
        <br />
        <b>제주골프</b>와 함께!
      </S3ContentTitle>
      <S3ContentSubText>지금 시작하세요!</S3ContentSubText>
    </S4Wrap>
  );
};

export default Section4Mobile;
