import styled from "styled-components";
import { BREAK_POINT } from "./common/windowSize";

export const FooterWrap = styled.div`
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "NotoSansKR_C";
`;

export const FooterBody = styled.div`
  padding: 27px 0;
  width: calc(100% - 640px);
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  @media (max-width: ${BREAK_POINT.footer.bp_first}px) {
    width: calc(100% - 320px);
  }
  @media (max-width: ${BREAK_POINT.footer.bp_second}px) {
    width: calc(100% - 160px);
  }
  @media (max-width: ${BREAK_POINT.footer.bp_third}px) {
    width: calc(100% - 80px);
    font-size: 18px;
  }
`;

export const FooterCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
`;

export const LogoImg = styled.img`
  max-width: 183px;
  width: 100%;
`;

export const InfoText = styled.p`
  padding-top: 28px;
  font-size: 0.52em;
  line-height: 18px;
  letter-spacing: -0.33px;
  margin-bottom: 0;
`;

export const InfoLinkWrap = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 0 10px;
`;
export const InfoLinkText = styled.span`
  padding-top: 28px;
  font-size: 0.52em;
  line-height: 18px;
  letter-spacing: -0.33px;
  cursor: pointer;
`;

export const CopyrightText = styled.span`
  display: block;
  width: 100%;
  font-size: 0.52em;
  line-height: 18px;
  letter-spacing: -0.33px;
  color: #00000057;
  text-align: right;
`;
