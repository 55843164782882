import { PulseLoader } from "react-spinners";

const BoardLoader = () => {
  return (
    <div className={"board_contents loading"}>
      <PulseLoader />
    </div>
  );
};

export default BoardLoader;
