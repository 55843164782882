import styled from "styled-components";

// formwrap

export const InputWrap = styled.div`
  position: relative;
  width: 100%;
`;

// formgroup
export const InputGroup = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${(props) => (props.$isMobile ? "calc(100%)" : "100%")};
  ${(props) =>
    props.$isMobile &&
    `flex-direction:column; justify-content:start; align-items:start;`};
`;

export const InputWithButton = styled.div`
  flex: 1;
  display: flex;
  justify-content: start;
  ${(props) => props.$isMobile && `flex-direction:column; width:calc(100%)`};
  align-items: stretch;
  gap: 13px 0;
`;

//label
export const InputLabel = styled.label`
  width: ${(props) => (props.$isMobile ? "150px" : "150px")};
`;

// input
export const InputText = styled.input`
  flex: 1;
  background-color: white;
  border: 2px solid #09f619;
  ${(props) => props.$isMobile && `width:calc(100% - 45px)`};
  padding: ${(props) => (props.$isMobile ? "20px" : "40px")};
  font-size: ${(props) => (props.$isMobile ? "0.7em" : "1.2em")};
  letter-spacing: -1.5px;
  &:focus {
    outline: none;
  }
`;
export const InputSelectWrap = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => props.$isMobile && `width:calc(100% - 45px)`};
  padding: ${(props) => (props.$isMobile ? "20px" : "40px")};
  border: 2px solid #09f619;
  z-index: 12;

  > .abIcon {
    position: absolute;
    display: flex;
    justify-content: end;
    align-items: center;
    right: 0;
    z-index: 11;
  }
`;
export const InputSelect = styled.select`
  flex: 1;
  background-color: white;
  border: none;
  ${(props) => props.$isMobile && `width:calc(100% - 45px)`};
  /* padding: ${(props) => (props.$isMobile ? "20px" : "40px")}; */
  font-size: ${(props) => (props.$isMobile ? "0.7em" : "1.2em")};
  letter-spacing: -1.5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &:focus {
    outline: none;
  }
`;

// checkbox
export const CheckBoxWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${(props) =>
    props.$isMobile &&
    `
  font-size:15px;
  `};
`;
export const InputCheckBox = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0 10px;
`;

// button
export const ValidButton = styled.button`
  border: none;
  background-color: ${(props) => (props.$isValid ? "#00FF72" : "#00FF72")};
  color: ${(props) => (props.$isValid ? "black" : "black")};
  font-size: ${(props) => (props.$isMobile ? "15px" : "30px")};
  letter-spacing: -1.5px;
  padding: ${(props) => (props.$isMobile ? "21px 37px" : "42px 74px")};
`;

export const SubmitButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) =>
    props.$isMobile ? "calc(100% - 55px)" : "calc(100% - 110px)"};
  color: white;
  font-family: "NotoSansKR_C";
  margin-top: 37px;
  padding: 0 55px 83px 55px;
  ${(props) =>
    props.$isMobile &&
    `
  margin-top: 18.5px;
  padding: 0 27.5px 41.5px 27.5px;
  
  
  `};

  > button {
    padding: 15px 0;
    width: 100%;
    background-color: #00ff72;
    border: none;

    color: black;
    font-family: "NotoSansKR_C";
    font-weight: bold;
    font-size: 1.5em;
    letter-spacing: -1.5px;
    ${(props) =>
      props.$isMobile &&
      `
    font-size: .8em;
  
  
  `};
  }
`;

// error
export const ValidErrorText = styled.p`
  color: red;
  font-size: 0.5em;
  width: 100%;
  text-align: left;
  padding-left: ${(props) => (props.$isMobile ? "unset" : "150px")};
`;

// helpText
export const HelpText = styled.p`
  margin: 15px 0;
  text-align: right;
  width: 100%;
  font-size: 1em;
  letter-spacing: -1.2px;
  font-weight: normal;

  ${(props) =>
    props.$isMobile &&
    `
  margin:7.5px 0;
  font-size:0.5em;
  `}
`;
