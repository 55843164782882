import styled from "styled-components";

export const BoardWrap = styled.div`
  background-color: #f2f2f2;
  font-size: 25px;
  padding: 3em 0;

  .board_info_area {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 2em 2em 2em;
    text-align: center;
    letter-spacing: -1.25px;
    font-weight: bold;

    .board_info_title {
      font-size: 1.8em;
    }
    .board_info_summary {
      color: #00c480;
    }
  }

  .board_g_body {
    ul {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, auto));
      column-gap: 5em;
      row-gap: 5em;

      list-style: none;
      margin: 0;
      padding: 0;
      justify-content: center;
      align-items: stretch;

      .board_g_item {
        width: 300px;
        .board_g_wrap {
          box-shadow: #00000082 0px 0px 12px 0;

          border-bottom-left-radius: 0.2em;
          border-bottom-right-radius: 0.2em;

          .board_g_item_image {
            width: 300px;
            height: 300px;
            background-color: #00000082;
          }
          .board_g_item_info {
            padding: 1em;
            font-size: 0.7em;
            overflow-wrap: break-word;
            .board_g_item_text {
              text-overflow: ellipsis;
              overflow-x: hidden;
              white-space: nowrap;
              &.bgi_type {
                font-size: 0.8em;

                margin: 0;
              }
              &.bgi_title {
                font-size: 1em;
                font-weight: bold;
                margin: 0.5em 0 1em 0;
              }
              &.bgi_date {
                font-size: 0.8em;
              }
            }
          }
        }
      }
    }
  }

  .board_contents {
    max-width: 1280px;
    margin: 0 auto;
    padding: 3em 2em 2em 2em;
    background-color: #fff;

    &.loading {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .contents_header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .contents_count {
        color: #00c480;
        font-weight: bold;
        letter-spacing: -1.25px;
      }
      .contents_input_group {
        position: relative;
        input[type="text"] {
          position: relative;
          padding: 0.8em calc(30px + 0.7em) 0.8em 1em;
          font-size: 0.8em;
          border: 1px solid #707070;
          border-radius: 3px;
          z-index: 11;
        }
      }
    }

    .contents_list_group {
      margin: 0.8em 0;
      .contents_list {
        list-style: none;
        padding: 0;
        margin: 0;
        border-top: 2px solid #000000;
        .contents_list_item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1.12em 0;
          border-bottom: 1px solid #ababab;

          &.item_empty {
            display: block;
            p {
              text-align: center;
              font-size: 0.8em;
            }
          }

          &.item_header {
            background-color: #f9f9f9;
          }
          .list_item {
            text-align: center;
            letter-spacing: -1.25px;

            &.list_item_num {
              width: 6em;
              flex: unset;
            }
            &.list_item_title {
              display: block;
              width: calc(100% - (6em + 7em));
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            &.list_item_date {
              width: 7em;
              flex: unset;
            }
          }
        }
      }
      .contents_view {
        list-style: none;
        padding: 0;
        margin: 0;
        border-top: 2px solid #000000;
        .view_item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1.12em 2.76em;
          border-bottom: 1px solid #ababab;

          &.view_contents {
            display: block;
          }

          &.view_header {
            background-color: #f9f9f9;
          }

          span {
            letter-spacing: -1.25px;
            &.view_item_title {
              font-weight: bold;
              max-width: 24em;
              width: 100%;
              @media (max-width: 765px) {
                max-width: 12em;
              }
            }
          }
          description {
            letter-spacing: -1.25px;
            line-height: 1.8em;
            word-break: keep-all;
          }
        }
      }
      .contents_button_group {
        margin: 3.36em 0;
        display: flex;
        justify-content: end;
        align-items: center;

        .contents_button {
          font-size: 25px;
          letter-spacing: -1.25px;
          padding: 0.72em 2.48em;
          background: #00c480;
          color: #fff;
          border: none;
          cursor: pointer;
        }
      }
    }
    .contents_list_paging {
      margin-top: 2em;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0 1.76em;
      letter-spacing: -1.25px;

      .list_paging_btn_group {
        gap: 0 0.4em;
        display: flex;
        justify-content: start;
        align-items: center;
        .list_paging_btn {
          padding: 0.4em;
          border: 1px solid #a7a7a7;
          border-radius: 3px;
          background-color: #fff;
          cursor: pointer;

          &.left {
            img {
              transform: rotateY(180deg);
            }
          }
          &.right {
            img {
              transform: rotateY();
            }
          }
        }
      }

      .list_paging_number_group {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 0 0.8em;
        .list_paging_number {
          cursor: pointer;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          &.active {
            &::after {
              position: absolute;
              content: "";
              bottom: -0.2em;
              left: 0;
              width: 100%;
              height: 2px;
              background-color: #00c480;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    font-size: 20px;
  }
  @media (max-width: 768px) {
    font-size: 18px;
  }
  @media (max-width: 500px) {
    font-size: 15px;
  }
  @media (max-width: 375px) {
    font-size: 13px;
  }
  @media (max-width: 320px) {
    font-size: 11px;
  }
`;

export const SufixIcon = styled.i`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  z-index: 111;
  margin-right: 0.5em;
  width: calc(30px + 0.5em);
  background-image: url(${(props) => props.$img});
  background-repeat: no-repeat;
  background-position: right center;
  @media (max-width: 1024px) {
    background-size: 50%;
  }
`;
