import {
  S2BtnArea,
  S2Button,
  S2Contents,
  S2ImgAbsolute,
  S2Title,
  S2Wrap,
} from "./section2MobileStyle";
import img2 from "../assets/img/2.png";

const Section2Mobile = () => {
  return (
    <S2Wrap>
      <S2Contents>
        <S2Title>
          티타임 조회부터 예약까지 한 번에,
          <br />
          손쉽게 라운딩!
        </S2Title>
        <S2BtnArea>
          <S2Button type={"button"}>Coming soon</S2Button>
        </S2BtnArea>
        <S2ImgAbsolute src={img2} />
      </S2Contents>
    </S2Wrap>
  );
};

export default Section2Mobile;
