import {
  Section2Contents,
  Section2Img,
  Section2More,
  Section2Text,
  Section2TextWrap,
  Section2Wrap,
} from "./section2Style";

import img2 from "../assets/img/2.png";

const Section2 = () => {
  return (
    <Section2Wrap>
      <Section2Contents>
        <Section2TextWrap>
          <Section2Text>
            티타임 조회부터 예약까지 한 번에, 손쉽게 라운딩!
          </Section2Text>
        </Section2TextWrap>
        {/* <Section2More>메뉴얼 살펴보기</Section2More> */}
        <Section2More>Grand Open</Section2More>
        <Section2Img src={img2} />
      </Section2Contents>
    </Section2Wrap>
  );
};

export default Section2;
