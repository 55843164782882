import styled from "styled-components";
import { BREAK_POINT } from "./common/windowSize";

export const Section2Wrap = styled.div`
  position: relative;
  padding: 191px 262px;
  z-index: 11;

  @media (max-width: ${BREAK_POINT.desktop}px) {
    padding: 191px 0;
    /* display: block; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Section2Contents = styled.div`
  position: relative;
  background-color: #756ef2;

  border-radius: 124px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 77px 81px;

  @media (max-width: ${BREAK_POINT.section2.first_crashed_size}px) {
    flex-direction: column;
    padding: 60px 81px;
  }
  @media (max-width: ${BREAK_POINT.section2.second_crashed_size}px) {
    font-size: 20px;
  }
  @media (max-width: ${BREAK_POINT.section2.third_crashed_size}px) {
    padding: 55px 70px;
    font-size: 18px;
  }
  @media (max-width: ${BREAK_POINT.section2.fourth_crahsed_size}px) {
    width: 676px;
  }
`;

export const Section2TextWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
`;
export const Section2Text = styled.span`
  position: relative;
  color: white;
  font-size: 1.6em;
  font-weight: bold;
  line-height: 75px;
  letter-spacing: -2.8px;
`;
export const Section2More = styled.a`
  text-decoration: none;
  color: black;
  font-size: 25px;
  font-weight: bold;
  line-height: 50px;
  letter-spacing: -1.25px;
  padding: 27px 68px;
  background-color: white;
  border-radius: 124px;
`;

export const Section2Img = styled.img`
  position: absolute;
  display: block;
  bottom: 0;
  left: 27px;
  max-width: 654px;
  max-height: 559px;
  width: 100%;
  z-index: -1;
`;
