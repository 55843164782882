import styled from "styled-components";

export const S4Wrap = styled.div`
  padding-top: 106px;
  padding-bottom: 25px;
  position: relative;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 13.5px;
`;
