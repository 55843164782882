import styled from "styled-components";
import { BREAK_POINT } from "./common/windowSize";

export const Section3Wrap = styled.div`
  background-color: #f6f6f6;
  border-top-right-radius: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
`;
export const StepWrap = styled.div`
  position: relative;
  padding: 40px 0;
  text-align: center;
  z-index: ${(props) => props.$zIndex};
  margin-top: ${(props) => props.$marginTop || 0};

  /* step5 */
  ${(props) => (props.$step == 5 ? "width : calc(100% - 299px); " : "")}

  @media (max-width: ${BREAK_POINT.section3.step4.bp_first}px) {
    margin-left: ${(props) =>
      props.$step == 4 ? `${BREAK_POINT.section3.step4.min_margin}px` : "0"};

    ${(props) =>
      props.$step == 4
        ? `
      width: 1000px;
      `
        : ""};
    /* font-size: 20px; */
  }
  @media (max-width: ${BREAK_POINT.section3.step4.bp_second}px) {
    ${(props) =>
      props.$step == 4
        ? `
      width: 800px;
      `
        : ""};
  }

  @media (max-width: ${BREAK_POINT.section3.first_crashed_size}px) {
    font-size: 20px;
  }
  @media (max-width: ${BREAK_POINT.section3.second_crashed_size}px) {
    font-size: 18px;
  }
  @media (max-width: ${BREAK_POINT.section3.third_crahsed_size}px) {
    font-size: 15px;
  }
`;

export const Section3Step4ImgArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;

  & img {
    width: calc(100% / 2);
  }
`;

export const Section3Step5TextWrap = styled.div``;

export const SectionStep = styled.div`
  width: ${(props) => (props.$step === 5 ? "calc(100%-299px)" : "100%")};
  display: flex;
  justify-content: start;
  align-items: center;
  gap: ${(props) => props.$gap || "0 138px"};

  @media (max-width: ${BREAK_POINT.desktop}px) {
    ${(props) =>
      props.$step === 5 &&
      `
        width: 100%;
        justify-content: center;
    `};
  }
`;
export const Section3HeaderText = styled.span`
  font-size: 9.88em;
  font-weight: 700;
  line-height: 206px;
  letter-spacing: -12.35px;
  color: #00c480;
  opacity: 0.06;
`;

export const Section3HeaderNum = styled(Section3HeaderText)`
  font-size: 9.88em;
  line-height: 205px;
  letter-spacing: -12.3px;
  opacity: 1;
`;

export const SectionText = styled.p`
  font-size: 1.8em;
  font-weight: bold;
  line-height: 75px;
  letter-spacing: -2.25px;
  margin: 30px 0 15px 0;
`;

export const SectionSubText = styled.p`
  text-align: center;
  font-size: ${(props) => props.$size || "30px"};
  font-weight: bold;
  font-size: 1em;
  line-height: 50px;
  letter-spacing: -1.5px;
  color: #00c480;
  margin: 15px 0 30px 0;
`;
export const SectionNormalText = styled.span`
  text-align: left;
  word-break: keep-all;
  font-size: 1em;
  line-height: 50px;
  font-weight: bold;
  letter-spacing: 1.25px;
  color: #626262;
  position: relative;
  z-index: 13;
  ${(props) =>
    props.$step === 4
      ? `
  padding-top: 50px;
  max-width: 510px;
  display: block;
  text-align: left;
  word-break: keep-all;
  `
      : ""};
  @media (max-width: ${BREAK_POINT.section3.step4.bp_second}px) {
    ${(props) =>
      props.$step === 4
        ? `
  
  max-width: 400px;
  
  `
        : ""};
  }
`;
export const Section3Img = styled.img`
  max-width: ${(props) => props.$maxWidth || "643px"};
  max-height: ${(props) => props.$maxHeight || "1169px"};
  width: ${(props) => (props.$cnt ? `calc(100% / ${props.$cnt})` : "100%")};

  @media (max-width: ${BREAK_POINT.desktop}px) {
    ${(props) =>
      props.$step === 2
        ? `
    width:calc(100% - 400px);
    `
        : ""}
  }
`;

export const SectionFlex = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: start;
`;
export const SectionFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  max-width: ${(props) => props.$maxWidth || "100%"};

  @media (max-width: ${BREAK_POINT.desktop}px) {
    ${(props) =>
      props.$step === 2
        ? `
    max-width:unset;
    `
        : ""};
  }
`;
export const SectionStep2Img = styled.img`
  position: absolute;
  bottom: -34%;
  right: -66%;
  max-width: 1245px;

  @media (max-width: 1440px) {
    right: -30%;
    z-index: -1;
    opacity: 0.2;
  }
`;
export const SectionFlexCenter = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SectionVariableImg = styled.img`
  max-width: ${(props) => props.$maxWidth};

  width: calc(100% / 3);
`;

export const AbsoluteElement = styled.div`
  position: absolute;
  top: ${(props) => props.$top};
  right: ${(props) => props.$right};
  z-index: -1;
`;
export const AbsoluteFlex = styled(AbsoluteElement)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 50px 0;
`;
