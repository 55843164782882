function fetchBoardList(params) {
  let boardList = null;

  const suspender = fetch(
    `https://api.jejugolf.kr/hp/list?page=${params.page ?? 1}&keyword=${
      params.keyword
    }`
    // `http://localhost:3000/hp/list?page=${params.page ?? 1}&keyword=${
    //   params.keyword
    // }`
  )
    .then((r) => r.json())
    .then((data) => {
      boardList = data;
    });

  return {
    read() {
      if (boardList === null) {
        throw suspender;
      } else {
        return boardList;
      }
    },
  };
}

function fetchBoardView(boardId) {
  let boardInfo = null;

  const suspender = fetch(`https://api.jejugolf.kr/hp/view/${boardId}`)
    .then((r) => r.json())
    .then((data) => {
      boardInfo = data;
    });
  // const suspender = fetch(`http://localhost:3000/hp/view/${boardId}`)
  //   .then((r) => r.json())
  //   .then((data) => {
  //     boardInfo = data;
  //   });

  return {
    read() {
      if (boardInfo === null) {
        throw suspender;
      } else {
        return boardInfo;
      }
    },
  };
}

function fetchData(params, boardId) {
  return {
    // boardInfo: fetchBoardView(boardId),
    boardList: fetchBoardList(params),
    boardInfo: !boardId ? null : fetchBoardView(boardId),
  };
}

export default fetchData;
