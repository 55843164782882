import {
  AbsoluteElement,
  AbsoluteFlex,
  Section3HeaderNum,
  Section3HeaderText,
  Section3Img,
  Section3Step4ImgArea,
  Section3Wrap,
  SectionFlex,
  SectionFlexCenter,
  SectionFlexColumn,
  SectionNormalText,
  SectionStep,
  SectionStep2Img,
  SectionSubText,
  SectionText,
  SectionVariableImg,
  StepWrap,
} from "./section3Style";

import img3 from "../assets/img/3.png";
import img4 from "../assets/img/4.png";
import img5 from "../assets/img/5.png";
import img6 from "../assets/img/6.png";
import img7 from "../assets/img/7.png";
import img8 from "../assets/img/8.png";
import img9 from "../assets/img/9.png";
import img10 from "../assets/img/10.png";
import start1 from "../assets/img/start.png";
import img11 from "../assets/img/11.png";
import img12 from "../assets/img/12.png";
import img13 from "../assets/img/13.png";
import img14 from "../assets/img/14.png";
import star_sm from "../assets/img/star_sm.png";
import { useEffect, useRef } from "react";
import mainfullImg from "../assets/img/mainfullImg.png";

const Section3 = ({ curIndex }) => {
  const topRef = useRef(null);
  useEffect(() => {
    if (curIndex === 1 && topRef.current != null) {
      topRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [curIndex]);

  return (
    <Section3Wrap ref={topRef}>
      <img src={mainfullImg} style={{ width: "100%" }} />
      {/* <StepWrap $zIndex={11}>
        <SectionStep>
          <Section3HeaderText>STEP</Section3HeaderText>
          <Section3HeaderNum>1</Section3HeaderNum>
        </SectionStep>
        <SectionText>
          간단한 회원가입 절차로 <b style={{ fontWeight: "700" }}>제주골프</b>를
          만나보세요
        </SectionText>
        <SectionSubText>본인인증만 하면 30초만에 OK!</SectionSubText>
        <Section3Img src={img3} />
      </StepWrap>
      <StepWrap $marginTop={"-128px"} $zIndex={12}>
        <div style={{ textAlign: "left", paddingBottom: "60px" }}>
          <Section3HeaderNum>2</Section3HeaderNum>
        </div>
        <SectionFlex>
          <Section3Img src={img4} $step={2} />
          <SectionFlexColumn $maxWidth={"515px"} $step={2}>
            <Section3HeaderText>STEP</Section3HeaderText>
            <SectionText style={{ textAlign: "left" }}>
              다양한 컨셉으로 라운딩하고,
              <br />
              각종 혜택은 덤으로!
            </SectionText>
            <SectionSubText $size={"25px"}>
              이색적인 코스들과 다양한 이벤트, 할인 혜택까지
            </SectionSubText>
            <SectionNormalText style={{ paddingTop: "50px" }}>
              제주골프만의 시즌 별 테마와 각종 이벤트, 할인 혜택을 보다 다양하게
              만나보세요. 맞춤 추천을 통해 원하는 플레이스를 방문하세요.
            </SectionNormalText>
          </SectionFlexColumn>
          <SectionStep2Img src={img5} />
        </SectionFlex>
      </StepWrap>
      <StepWrap style={{ paddingTop: "200px" }} $zIndex={13}>
        <SectionText>
          간편한 예약 시스템,
          <br />
          티타임 조회부터 결제까지 손쉽게.
        </SectionText>
        <SectionSubText $size={"25px"}>
          원하는 장소와 티타임 정보를 한 눈에!
        </SectionSubText>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: "118px",
          }}
        >
          <SectionNormalText
            style={{
              paddingTop: "50px",
              maxWidth: "666px",
              display: "block",
              textAlign: "center",
              wordBreak: "keep-all",
            }}
          >
            사용자 중심의 편리한 인터페이스로 원하는 정보를 손쉽게 조회하세요.
            간편한 절차를 통해 보다 빠르게 티타임을 예약하세요.
          </SectionNormalText>
        </div>

        <SectionFlexCenter>
          <SectionVariableImg src={img6} $maxWidth={"643px"} />
          <SectionVariableImg src={img7} $maxWidth={"643px"} />
          <SectionVariableImg src={img8} $maxWidth={"643px"} />
          <AbsoluteFlex $top={"-400px"} $right={"5%"}>
            <Section3HeaderNum>3</Section3HeaderNum>
            <Section3HeaderText>STEP</Section3HeaderText>
          </AbsoluteFlex>
        </SectionFlexCenter>
      </StepWrap>
      <StepWrap style={{ paddingTop: "152px" }} $zIndex={"11"} $step={4}>
        <SectionStep $gap={"0 32px"}>
          <Section3HeaderText>STEP</Section3HeaderText>
          <Section3HeaderNum>4</Section3HeaderNum>
        </SectionStep>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <SectionText style={{ textAlign: "left" }}>
            성장하는 내 모습,
            <br />한 눈에 알아보는 내 스코어.
          </SectionText>
          <SectionSubText $size={"25px"} style={{ textAlign: "left" }}>
            내 스코어를 효율적으로 기록하고 관리하세요.
          </SectionSubText>
        </div>

        <SectionNormalText $step={4}>
          지난 라운딩뿐만 아니라, 현재 진행중인 라운딩 장소와 기록도 실시간으로
          편리하게 불러오세요. 그리고 제주와 함께 효율적으로 스코어를 기록하고,
          관리하세요.
        </SectionNormalText>
        <Section3Step4ImgArea>
          <Section3Img src={img9} $maxWidth={"593px"} $maxHeight={"1038px"} />
          <Section3Img
            src={img10}
            $maxWidth={"654px"}
            $maxHeight={"698px"}
            style={{ marginTop: "-100px", marginLeft: "-50px" }}
          />
        </Section3Step4ImgArea>
        <AbsoluteElement $top={"500px"} $right={"150px"}>
          <Section3Img src={start1} $maxWidth={"92px"} $maxHeight={"92px"} />
        </AbsoluteElement>
      </StepWrap>
      <StepWrap style={{ paddingTop: "100px" }} $zIndex={"11"} $step={5}>
        <SectionStep $gap={"0 32px"} style={{ margin: "0 auto" }} $step={5}>
          <Section3HeaderText>STEP</Section3HeaderText>
          <Section3HeaderNum>5</Section3HeaderNum>
        </SectionStep>
        <SectionText>
          내 스타일대로 조인,
          <br />
          그룹 라운딩으로 더욱 즐겁게!
        </SectionText>
        <SectionSubText $size={"25px"}>
          내 플레이 스타일에 맞는 그룹에 지금, 자유롭게 조인!
        </SectionSubText>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SectionNormalText
            style={{
              paddingTop: "50px",
              maxWidth: "964px",
              display: "block",

              textAlign: "center",
            }}
          >
            취향에 맞는 다양한 키워드로 맞춤 조인 서비스를 관리하세요. 그룹
            생성부터 조인까지, 한 눈에 알아보기 쉬운 조인 시스템으로 더욱 즐거운
            라운딩 경험이 될 거에요.
          </SectionNormalText>
        </div>

        <SectionFlex>
          <Section3Img
            src={img11}
            $maxWidth={"450px"}
            $maxHeight={"973px"}
            $cnt={4}
          />
          <Section3Img
            src={img12}
            $maxWidth={"450px"}
            $maxHeight={"973px"}
            $cnt={4}
            style={{ marginTop: "82px" }}
          />
          <Section3Img
            src={img13}
            $maxWidth={"450px"}
            $maxHeight={"973px"}
            $cnt={4}
            style={{ marginTop: "142px" }}
          />
          <Section3Img
            src={img14}
            $maxWidth={"450px"}
            $maxHeight={"973px"}
            $cnt={4}
          />
        </SectionFlex>
        <AbsoluteElement $top={"20%"} $right={"10%"} style={{ zIndex: "-1" }}>
          <Section3Img src={start1} $maxWidth={"92px"} $maxHeight={"92px"} />
        </AbsoluteElement>
        <AbsoluteElement $top={"10%"} $right={"30%"} style={{ zIndex: "-1" }}>
          <Section3Img src={star_sm} $maxWidth={"76px"} $maxHeight={"76px"} />
        </AbsoluteElement>
      </StepWrap> */}
    </Section3Wrap>
  );
};

export default Section3;
