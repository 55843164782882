import styled from "styled-components";

export const S3Wrap = styled.div`
  background-color: #f6f6f6;
  border-top-right-radius: 150px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  font-size: 13.5px;
`;

export const S3StepContents = styled.div`
  position: relative;
  padding: 30px 10px 0 10px;
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;

  &::before {
    background-image: url(${(props) => props.$bg});
    background-size: cover;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #fff;
    opacity: 0.2;
    z-index: 1;
  }
`;

export const S3FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
export const S3FlexStart = styled.div`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  gap: 0 30px;
`;
export const S3StepTitle = styled.span`
  font-size: 8em;
  line-height: 103px;
  letter-spacing: -6.175px;
  color: #00c480;
  opacity: 0.06;
  font-weight: 700;
  padding: 20px 0;
`;
export const S3StepNumber = styled(S3StepTitle)`
  opacity: 1;
`;

export const S3ContentTitle = styled.span`
  text-align: center;
  word-break: keep-all;
  font-size: 2.2em;
  line-height: 45.5px;
  letter-spacing: -1.125px;
  font-weight: bold;
  b {
    font-weight: 700;
  }
  margin-bottom: 10px;
`;
export const S3ContentSubText = styled.span`
  font-size: 1.2em;
  line-height: 25px;
  font-weight: bold;
  letter-spacing: -0.75px;
  color: #00c480;
  margin-bottom: 20px;
`;
export const S3ContentSummary = styled.span`
  font-size: 1em;
  line-height: 25px;
  letter-spacing: -0.75px;
  color: #626262;
  font-weight: bold;
  word-break: keep-all;
`;

export const S3ContentCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 20px 0;
`;

export const S3Absolute = styled.div`
  position: absolute;
  top: ${(props) => props.$top};
  left: ${(props) => props.$left};
  z-index: -1;
`;
