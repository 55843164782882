import { Suspense, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BoardWrap } from "../component/board/boardStyle";
import BoardItem from "../component/board/boardItem";
import fetchData from "../api/board/fetchData";
import BoardLoader from "../component/board/boardLoader";

const BoardViewPage = () => {
  const navigator = useNavigate();
  const params = useParams().id;

  const onClickBackToBoard = () => navigator("/board");

  useEffect(() => {}, [params]);

  return (
    <BoardWrap>
      <div className="board_info_area">
        <p className="board_info_title">게시판</p>
        <p className="board_info_summary">
          <small>제주골프의 새로운 소식을 알려드립니다.</small>
        </p>
      </div>
      <div className="board_contents">
        <div className="contents_list_group">
          <Suspense fallback={<BoardLoader />}>
            <BoardItem resource={fetchData({}, params)} />
          </Suspense>
          {/* <ul className="contents_view">
            <li className="view_item view_header">
              <span className={"view_item_title"}>
                제주골프 시스템 점검에 따른 온라인 이용 일시 중지안내
              </span>
              <span className={"view_item_date"}>2024-06-27</span>
            </li>
            <li className="view_item">
              <description className={"view_item_contents"}>
                시스템 점검 안내 시스템 점검에 따른 제주항공 이용 일시 중지 안내
                -시스템 작업일시 : 2024.06.20(목) 00:00 ~ 04:30(약 4시간30분)
                -제한 서비스: 작업시간 동안 예약 / 결제 / 취소(환불)요청 불가
                시스템 점검으로 결제 및 취소(환불) 요청은 시스템 점검 시간
                이후에 이용 부탁드리겠습니다. 고객 여러분의 양해 부탁드립니다.
                감사합니다.
              </description>
            </li>
          </ul> */}

          <div className={"contents_button_group"}>
            <button
              type="button"
              className={"contents_button"}
              onClick={onClickBackToBoard}
            >
              목록
            </button>
          </div>
        </div>
      </div>
    </BoardWrap>
  );
};

export default BoardViewPage;
