import { useEffect, useState } from "react";
import img1 from "../assets/img/1.png";
import {
  S1Video,
  S1VideoWrap,
  Section1BtnItem,
  Section1BtnWarp,
  Section1Img,
  Section1TextCol,
  Section1TextSpan,
  Section1TextSubSpan,
  Section1Wrap,
  SectionContents,
} from "./section1Style";
import YouTube from "react-youtube";

const Section1 = () => {
  const [init, setInit] = useState(false);
  const [vloading, setVloading] = useState(true);
  const [googleToggle, setGoogleToggle] = useState(false);
  const [appleToggle, setAppleToggle] = useState(false);

  const onClickDownload = (dType) => {
    if (dType == "android") {
      window.open(
        "http://play.google.com/store/apps/details?id=com.jejugolf.erum.app"
      );
    } else {
      window.open(
        "https://apps.apple.com/app/%EC%A0%9C%EC%A3%BC%EA%B3%A8%ED%94%84/id6480164472"
      );
    }
  };
  useEffect(() => {
    if (!init) {
      setInit(true);
    }
  }, []);
  return (
    // https://www.youtube.com/embed/h92T7SEXZI4?controls=0&showinfo=0&modestbranding=0&rel=0&disablekb=1&loop=1&enablejsapi=1&origin=https%3A%2F%2Fcompany.golfzon.com&widgetid=1
    <Section1Wrap>
      <S1VideoWrap style={{ position: "absolute" }}>
        <S1Video src={"/videos/bgvideo.mp4"} loop autoPlay muted />
      </S1VideoWrap>

      <SectionContents $init={init}>
        <Section1TextCol $init={init}>
          <Section1TextSpan $init={init}>
            제주골프를 100% 즐기는 법
            <br />
            제주골프 어플로 한번에!
          </Section1TextSpan>
          <Section1TextSubSpan $init={init} style={{ wordBreak: "keep-all" }}>
            예약, 조인, 제주골프 아마추어 리그전, 캐디백 딜리버리, 스코어관리,
            렌탈서비스, 숙박, 해외투어, 중고클럽마켓, 현장레슨 등 제주골프와
            함께 케어 받으세요.
          </Section1TextSubSpan>
          <Section1BtnWarp>
            <Section1BtnItem
              type="button"
              $isOutline={false}
              $toggle={googleToggle}
              onMouseEnter={() => !googleToggle && setGoogleToggle(true)}
              onMouseLeave={() => googleToggle && setGoogleToggle(false)}
              onClick={() => onClickDownload("android")}
            >
              <span className={"untoggle"}>Google Play</span>
              <span className={"toggle"}>Download</span>
            </Section1BtnItem>
            <Section1BtnItem
              type="button"
              $toggle={appleToggle}
              $isOutline={true}
              onMouseEnter={() => !appleToggle && setAppleToggle(true)}
              onMouseLeave={() => appleToggle && setAppleToggle(false)}
              onClick={() => onClickDownload("ios")}
            >
              <span className={"untoggle"}>App Store</span>
              <span className={"toggle"}>Download</span>
            </Section1BtnItem>
          </Section1BtnWarp>
        </Section1TextCol>
        {/* <Section1Img src={img1} $init={init} /> */}
      </SectionContents>
    </Section1Wrap>
  );
};

export default Section1;
