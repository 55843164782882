import styled from "styled-components";

import eventImg from "../assets/img/pc_event0329.jpg";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

const Sectionevent = () => {
  const navi = useNavigate();

  return (
    <Wrap $isMobile={isMobile}>
      <div className={"imgArea"}>
        <img
          src={eventImg}
          onClick={() => navi("/event")}
          style={{ cursor: "pointer" }}
        />
      </div>
    </Wrap>
  );
};

export default Sectionevent;

const Wrap = styled.div`
  padding: ${(props) => (props.$isMobile ? "34px 0" : "68px 0")};
  .imgArea {
    ${(props) =>
      props.$isMobile
        ? `
    width:calc(100%);
    
    `
        : `
    width: calc(100% - 640px);
    margin: 0 320px;`}

    > img {
      display: block;
      width: 100%;
    }
  }
`;
