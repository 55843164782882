import {
  S3Absolute,
  S3ContentCol,
  S3ContentSubText,
  S3ContentSummary,
  S3ContentTitle,
  S3FlexBetween,
  S3FlexStart,
  S3StepContents,
  S3StepNumber,
  S3StepTitle,
  S3Wrap,
} from "./section3MobileStyle";
import step1Img from "../assets/img/3.png";
import step2Img from "../assets/img/4.png";
import bg2Img from "../assets/img/5.png";
import step3Img1 from "../assets/img/6.png";
import step3Img2 from "../assets/img/7.png";
import step3Img3 from "../assets/img/8.png";
import step4Img1 from "../assets/img/9.png";
import step4Img2 from "../assets/img/10.png";
import star_m from "../assets/img/start.png";
import star_sm from "../assets/img/star_sm.png";
import step5Img1 from "../assets/img/11.png";
import step5Img2 from "../assets/img/12.png";
import step5Img3 from "../assets/img/13.png";
import step5Img4 from "../assets/img/14.png";
import { useEffect, useRef } from "react";
import mainfullImg from "../assets/img/mainfullImg.png";

const Section3Mobile = ({ curIndex }) => {
  const topRef = useRef(null);
  useEffect(() => {
    if (curIndex == 1 && topRef.current != null) {
      topRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [curIndex]);
  return (
    <S3Wrap ref={topRef}>
      <img src={mainfullImg} style={{ width: "100%" }} />
      {/* <S3Step1 />
      <S3Step2 />
      <S3Step3 />
      <S3Step4 />
      <S3Step5 /> */}
    </S3Wrap>
  );
};

const S3Step1 = () => {
  return (
    <S3StepContents>
      <S3FlexStart>
        <S3StepTitle>STEP</S3StepTitle>
        <S3StepNumber>1</S3StepNumber>
      </S3FlexStart>
      <S3ContentTitle>
        간단한 회원가입 절차로 <b>제주골프</b>를 만나보세요.
      </S3ContentTitle>
      <S3ContentSubText>본인인증만 하면 30초만에 OK!</S3ContentSubText>
      <img src={step1Img} width={"100%"} />
    </S3StepContents>
  );
};

const S3Step2 = () => {
  return (
    <S3StepContents $bg={bg2Img}>
      <S3FlexStart>
        <S3StepTitle>STEP</S3StepTitle>
        <S3StepNumber>2</S3StepNumber>
      </S3FlexStart>
      <S3FlexBetween>
        <img src={step2Img} style={{ width: "calc(100%/2)", zIndex: "111" }} />
        <S3ContentCol>
          <S3ContentTitle>
            다양한 컨셉으로 라운딩하고
            <br />
            <br />
            각종 혜택은 덤으로!
          </S3ContentTitle>
        </S3ContentCol>
      </S3FlexBetween>
      <S3ContentSubText style={{ paddingTop: "20px" }}>
        이색적인 코스들과 다양한 이벤트, 할인 혜택까지
      </S3ContentSubText>
      <S3ContentSummary style={{ textAlign: "center" }}>
        제주골프만의 시즌 별 테마와 각종 이벤트, 할인 혜택을 보다 다양하게
        만나보세요. 맞춤 추천을 통해 원하는 플레이스를 방문하세요.
      </S3ContentSummary>
    </S3StepContents>
  );
};

const S3Step3 = () => {
  return (
    <S3StepContents style={{ paddingTop: "127px" }}>
      <S3FlexStart>
        <S3StepTitle>STEP</S3StepTitle>
        <S3StepNumber>3</S3StepNumber>
      </S3FlexStart>
      <S3ContentTitle>
        간편한 예약 시스템,
        <br />
        티타임 조회 부터 결제까지 손쉽게
      </S3ContentTitle>
      <S3ContentSubText>원하는 장소와 티타임 정보를 한 눈에!</S3ContentSubText>
      <S3ContentSummary style={{ textAlign: "center" }}>
        사용자 중심의 편리한 인터페이스로 원하는 정보를 손쉽게 조회하세요.
        <br />
        간편한 절차를 통해 보다 빠르게 티타임을 예약하세요.
      </S3ContentSummary>
      <S3FlexBetween style={{ paddingTop: "50px" }}>
        <img src={step3Img1} style={{ width: "calc(100% / 3)" }} />
        <img src={step3Img2} style={{ width: "calc(100% / 3)" }} />
        <img src={step3Img3} style={{ width: "calc(100% / 3)" }} />
      </S3FlexBetween>
    </S3StepContents>
  );
};

const S3Step4 = () => {
  return (
    <S3StepContents style={{ paddingTop: "70px" }}>
      <S3FlexStart>
        <S3StepTitle>STEP</S3StepTitle>
        <S3StepNumber>4</S3StepNumber>
      </S3FlexStart>
      <S3ContentTitle style={{ textAlign: "left", width: "100%" }}>
        성장하는 내 모습,
        <br />한 눈에 알아보는 내 스코어
      </S3ContentTitle>
      <S3ContentSubText style={{ textAlign: "left", width: "100%" }}>
        내 스코어를 효율적으로 기록하고 관리하세요
      </S3ContentSubText>
      <S3ContentSummary style={{ textAlign: "left" }}>
        지난 라운딩뿐만 아니라, 현재 진행중인 라운딩 장소와 기록도 실시간으로
        편리하게 불러오세요. 그리고 제주 와 함께 효율적으로 스코어를 기록하고,
        관리하세요.
      </S3ContentSummary>
      <S3FlexBetween style={{ paddingTop: "50px" }}>
        <img src={step4Img1} style={{ width: "calc(100%/2)" }} />
        <img src={step4Img2} style={{ width: "calc(100%/2)" }} />
      </S3FlexBetween>
      <S3Absolute $top={"30%"} $left={"80%"}>
        <img src={star_m} style={{ width: "25px" }} />
      </S3Absolute>
    </S3StepContents>
  );
};

const S3Step5 = () => {
  return (
    <S3StepContents style={{ paddingTop: "70px", paddingBottom: "70px" }}>
      <S3FlexStart>
        <S3StepTitle>STEP</S3StepTitle>
        <S3StepNumber>5</S3StepNumber>
      </S3FlexStart>
      <S3ContentTitle>
        내 스타일대로 조인 <br />
        그룹 라운딩으로 더욱 즐겁게!
      </S3ContentTitle>
      <S3ContentSubText>
        내 플레이 스타일에 맞는 그룹에 지금, 자유롭게 조인!
      </S3ContentSubText>
      <S3ContentSummary style={{ textAlign: "center" }}>
        취향에 맞는 다양한 키워드로 맞춤 조인 서비스를 관리하세요.
        <br /> 그룹 생성부터 조인까지, 한 눈에 알아보기 쉬운 조인 시스템으로
        더욱 즐거운 라운딩 경험이 될 거에요.
      </S3ContentSummary>
      <S3FlexBetween style={{ marginLeft: "calc(-100%/3)" }}>
        <img src={step5Img1} style={{ width: "calc(100%/3)" }} />
        <img
          src={step5Img2}
          style={{ width: "calc(100%/3)", marginTop: "40px" }}
        />
        <img
          src={step5Img3}
          style={{ width: "calc(100%/3)", marginTop: "80px" }}
        />
        <img src={step5Img4} style={{ width: "calc(100%/3)" }} />
      </S3FlexBetween>

      <S3Absolute $top={"30%"} $left={"80%"}>
        <img src={star_sm} style={{ width: "20px" }} />
      </S3Absolute>
      <S3Absolute $top={"50%"} $left={"90%"}>
        <img src={star_m} style={{ width: "25px" }} />
      </S3Absolute>
    </S3StepContents>
  );
};

export default Section3Mobile;
