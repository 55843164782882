import "./App.css";
import EventPage from "./page/event";
import Header from "./page/header/header";
import MainPage from "./page/index";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import HelmetMeta from "./utils/commonHelmet";
import TempCnt from "./page/tempCnt";
import NotFoundPage from "./page/notFound";
import AppPrivacyPage from "./component/privacy/appPrivacy";
import AppUserPrivacy from "./component/privacy/appUserPrivacy";
import BoardPage from "./page/board";
import BoardViewPage from "./page/boardView";

function App() {
  // useEffect(() => {
  //   if (isMobile) {
  //     alert(
  //       "본 사이트는 Desktop에 최적화되어 있습니다. PC 버전으로 접속해주시기 바랍니다."
  //     );
  //     return;
  //   }
  // }, [isMobile]);

  // if (isMobile) {
  //   return <MobilePage />;
  // }
  const [curIndex, setCurIndex] = useState(0);

  useEffect(() => {
    setCurIndex(0);
    return () => {
      setCurIndex(0);
    };
  }, []);

  const changeIndex = (index) => {
    setCurIndex(index);
  };

  return (
    <>
      <HelmetMeta
      // title="Jeju golf"
      //   description={"제주골프를 100% 즐기는 법! 제주골프 전부를 한 곳에서"}
      //   imagesrc={"/share_img.png"}
      //   url={}
      />
      <Routes>
        <Route element={<Header changeIndex={changeIndex} />}>
          <Route path="/" element={<MainPage curIndex={curIndex} />} />
          <Route path="/event" element={<EventPage />} />
          <Route path="/board" element={<BoardPage />} />
          <Route path="/board/view/:id" element={<BoardViewPage />} />
          <Route path="/tempcnt" element={<TempCnt />} />
          <Route path="/privacy" element={<AppPrivacyPage />} />
          <Route path="/userPrivacy" element={<AppUserPrivacy />} />
          {/* <Route path="/*" element={<NotFoundPage />} /> */}
        </Route>
      </Routes>
    </>
  );
}

export default App;
