import styled from "styled-components";

export const S2Wrap = styled.div`
  position: relative;
  padding: 95px 0;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13.5px;
`;
export const S2Contents = styled.div`
  position: relative;
  background-color: #756ef2;
  /* border-radius: 124px; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 38px 40px;
`;

export const S2Title = styled.span`
  font-size: 1.6em;
  line-height: 37.5px;
  letter-spacing: -1.4px;
  color: white;
  font-weight: bold;
`;

export const S2BtnArea = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
`;

export const S2Button = styled.button`
  text-align: right;
  border: none;
  background-color: white;
  font-size: 1em;
  line-height: 25px;
  letter-spacing: -0.675px;
  border-radius: 48px;
  padding: 13px 33px;
`;
export const S2ImgAbsolute = styled.img`
  position: absolute;
  bottom: 100px;
  left: 0;
  z-index: -1;
  max-width: 300px;
`;
