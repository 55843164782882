import styled from "styled-components";
import { BREAK_POINT } from "../../component/common/windowSize";

export const HeaderWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 91px;
  @media (max-width: ${BREAK_POINT.mobile_lg}px) {
    height: 50px;
  }
`;
export const HeaderLogo = styled.img`
  position: absolute;
  left: 54px;
  max-width: 137px;
  width: 100%;
  z-index: 111113;

  @media (max-width: ${BREAK_POINT.mobile_lg}px) {
    left: 10px;
    max-width: 80px;
  }
`;
export const HeaderMenu = styled.div`
  margin: 0 auto;
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  gap: 0 40px;

  @media (max-width: ${BREAK_POINT.mobile_lg}px) {
    display: none;
  }
`;
export const HeaderMenuItem = styled.span`
  font-family: "NotoSansKR_C";
  font-weight: 500;
  font-size: 20px;
  line-height: 56px;
  letter-spacing: -1px;
  color: #191919;
`;
