import React from "react";
import {
  HeaderLogo,
  HeaderMenu,
  HeaderMenuItem,
  HeaderWrap,
} from "./headerStyle";
import Logo from "../../assets/img/jejugolf_logo_final.png";
import { Outlet, useNavigate } from "react-router-dom";
import FooterMobile from "../../component/footerMobile";
import Footer from "../../component/footer";
import { isMobile } from "react-device-detect";
import HeaderMobile from "./headerMobile";

const Header = ({ changeIndex }) => {
  const navi = useNavigate();
  const moveEventPage = () => {
    navi("/event");
  };
  const moveBoardPage = () => {
    navi("/board");
  };
  const moveHomePage = (num) => {
    navi("/");
    if (num != null && num != undefined) {
      changeIndex(num);
      return;
    }
    changeIndex(0);
  };

  const moveHomePageMobile = (targetIndex) => changeIndex(targetIndex);

  return (
    <>
      <HeaderWrap>
        <HeaderLogo src={Logo} onClick={() => moveHomePage()} />
        <HeaderMenu>
          <HeaderMenuItem onClick={() => moveHomePage(1)}>
            서비스 소개
          </HeaderMenuItem>
          <HeaderMenuItem onClick={() => moveHomePage(2)}>
            다운로드
          </HeaderMenuItem>
          <HeaderMenuItem onClick={() => moveHomePage(3)}>
            제휴문의
          </HeaderMenuItem>
          <HeaderMenuItem onClick={() => moveBoardPage()}>
            게시판
          </HeaderMenuItem>
          {/* <HeaderMenuItem onClick={() => moveEventPage()}>
            이벤트
          </HeaderMenuItem> */}
        </HeaderMenu>
        {isMobile && <HeaderMobile refChange={moveHomePageMobile} />}
      </HeaderWrap>
      <Outlet />
      {/* {isMobile ? <FooterMobile /> : <Footer />} */}
    </>
  );
};

export default Header;
