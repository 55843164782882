import { useEffect, useMemo, useState } from "react";
import { HiRefresh } from "react-icons/hi";
import styled from "styled-components";

const CustomTimer = ({ refresh }) => {
  const [sec, setSec] = useState(60);

  const formatSecond = useMemo(() => {
    return sec.toString().padStart(2, "0");
  }, [sec]);

  const forceRefresh = () => {
    refresh();
    setSec(60);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (sec > 0) {
        setSec(sec - 1);
      } else {
        refresh();
        setSec(60);
      }
    }, 1000);

    return () => clearInterval(interval);
  });

  return (
    <TimerWrap>
      <p>{formatSecond} 초 후 새로고침 됩니다.</p>
      <div className={"timerForceRefresh"} onClick={forceRefresh}>
        <span>강제 새로고침</span>
        <HiRefresh />
      </div>
    </TimerWrap>
  );
};

export default CustomTimer;

const TimerWrap = styled.div`
  position: relative;
  display: block;
  p {
    font-size: 0.7em;
  }

  .timerForceRefresh {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.7em;
    padding: 5px 10px;
    box-shadow: 0px 0px 20px 0px #00000023;
    background-color: white;
  }
`;
