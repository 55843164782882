import { SufixIcon } from "./boardStyle";
import SearchImg from "../../assets/img/search_ico.png";
import { Paging } from "../pagination/paging";
import { useRef } from "react";

const BoardList = ({ resourse, onMovePage, setPage, setKeyword }) => {
  const dataList = resourse.boardList.read();
  const keywordRef = useRef(null);

  const onChangePage = (page) => setPage(page);
  const onClickKeywordSearch = () => {
    setKeyword(keywordRef.current.value);
  };

  return (
    <div className="board_contents">
      {/* 게시판 헤더 */}
      <div className="contents_header">
        <span className="contents_count">총 {dataList.total}건</span>
        <div className="contents_input_group">
          <SufixIcon
            className="contents_input_icon"
            $img={SearchImg}
            onClick={onClickKeywordSearch}
          ></SufixIcon>
          <input
            type="text"
            placeholder="제목을 입력해주세요"
            ref={keywordRef}
          />
        </div>
      </div>
      {/* 게시판 본문 */}
      <div className="contents_list_group">
        <ul className="contents_list">
          <li className="contents_list_item item_header">
            <span className="list_item list_item_num">번호</span>
            <span className="list_item list_item_title">
              <b>제목</b>
            </span>
            <span className="list_item list_item_date">등록일</span>
          </li>
          {dataList.total == 0 ? (
            <li className={"contents_list_item item_empty"}>
              <p>등록된 게시글이 존재하지 않습니다.</p>
            </li>
          ) : (
            dataList.data.map(({ id, title, use_yn, created_at }, index) => (
              <li
                className="contents_list_item"
                key={id}
                onClick={() => onMovePage(id)}
              >
                <span className="list_item list_item_num">
                  {dataList.total - (index + (dataList.curPage - 1) * 10)}
                </span>
                <span className="list_item list_item_title">{title}</span>
                <span className="list_item list_item_date">
                  {new Date(created_at).toLocaleDateString()}
                </span>
              </li>
            ))
          )}
        </ul>
      </div>
      {/* 페이징 처리 */}
      <Paging
        curPage={dataList.curPage}
        totalElement={dataList.total}
        take={10}
        changePage={onChangePage}
      />
    </div>
  );
};

export default BoardList;
