import styled from "styled-components";

export const S1Wrap = styled.div`
  position: relative;
  display: flex;
  font-family: "Nanum";
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 460px;
  background-color: #00c480;
  border-bottom-left-radius: 150px;
  z-index: 10;
  font-size: 13.5px;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;

  &::before {
    content: "";
    background-image: url(${(props) => props.$bg});
    opacity: 0.1;
    position: absolute;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
  }
`;
export const S1MBgWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  &::before {
    content: "";
    position: absolute;

    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #000;
  }
`;
export const S1BVideo = styled.video`
  position: relative;
  opacity: 0.4;
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: 490px;
`;
export const S1Contents = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  opacity: 1;
  padding: 0 24px;
`;
export const S1Title = styled.p`
  color: white;
  font-size: 2.2em;
  line-height: 40px;
  font-weight: 700;
  letter-spacing: -2.75px;
  word-break: keep-all;
  margin: 0px 0px 13px 0px;
`;

export const S1SubTitle = styled.p`
  color: white;
  font-size: 1em;
  line-height: 25px;
  letter-spacing: -0.2375;
  margin: 0px 0px 13px 0px;
  word-break: keep-all;
`;

export const S1BtnArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: center;
  gap: 0 10px;
  padding-top: 70px;
`;
export const S1Button = styled.button`
  background-color: white;
  font-size: 0.72em;
  line-height: 18px;
  color: #00c480;
  letter-spacing: -0.45;
  border: 1px solid white;
  border-radius: 38px;
  padding: 12px 25px;
  ${(props) => props.$isOutline && "background-color:transparent; color:white"};
  position: relative;
  overflow: hidden;

  > span {
    display: block;
    &.untoggle {
      transform: translateX(${(props) => (props.$toggle ? "-200%" : "0")});
      transition: transform 0.3s ease-in-out;
    }
    &.toggle {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translateX(${(props) => (props.$toggle ? "0" : "200%")});
      transition: transform 0.3s ease-in-out;
    }
  }
`;
