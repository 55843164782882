const BoardItem = ({ resource }) => {
  const boardItem = resource.boardInfo.read();
  return (
    <ul className="contents_view">
      <li className="view_item view_header">
        <span className={"view_item_title"}>
          {boardItem.title}
          {/* 제주골프 시스템 점검에 따른 온라인 이용 일시 중지안내 */}
        </span>
        <span className={"view_item_date"}>
          {new Date(boardItem.created_at).toLocaleDateString()}
        </span>
      </li>
      <li className="view_item view_contents">
        <description className={"view_item_contents"}>
          <div dangerouslySetInnerHTML={{ __html: boardItem.contents }}></div>
        </description>
      </li>
    </ul>
  );
};

export default BoardItem;
