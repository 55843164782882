import {
  ContactInfo,
  ContactValue,
  FormError,
  FormGroup,
  FormInput,
  FormLabel,
  FormWrapFlex,
  HeaderTitle,
  Section5Body,
  Section5Wrap,
  SendButton,
  SummaryText,
} from "./section5Style";
import { HiMail, HiOutlineMail, HiPhone } from "react-icons/hi";
import { HiMiniPlay } from "react-icons/hi2";
import fb from "../assets/img/facebook.png";
import insta from "../assets/img/insta.png";
import yt from "../assets/img/youyube.png";
import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { emailReg } from "./common/validationStd";
import { customAxios } from "../axios/axios.config";

const Section5 = ({ curIndex }) => {
  const topRef = useRef(null);
  useEffect(() => {
    if (curIndex === 3 && topRef.current != null) {
      topRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [curIndex]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    console.log("submit data ", data);

    const result = await customAxios.post(`/contact`, data);
    switch (result.status) {
      case 201:
        alert("성공적으로 요청되었습니다.");
        reset();
        break;
      default:
        alert(
          "요청중 오류가 발생했습니다. 잠시 후 다시 이용해주시기 바랍니다."
        );
        break;
    }
  };

  return (
    <Section5Wrap ref={topRef}>
      <Section5Body>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            justifyContent: "space-between",
          }}
        >
          <div>
            <HeaderTitle>Contact Us</HeaderTitle>
            <SummaryText>필수 정보와 문의 내용을 입력해주세요</SummaryText>
          </div>
          <div>
            <ContactInfo>
              <HiOutlineMail size={25} color="#6a6a6a" />
              <ContactValue>golfrgo@jejugolf.kr</ContactValue>
            </ContactInfo>
            <ContactInfo style={{ marginTop: "40px" }}>
              <img src={insta} width={50} />
              <img src={yt} width={50} />
              <img src={fb} width={50} />
            </ContactInfo>
          </div>
        </div>
        <form
          style={{ flex: 1, display: "flex", flexDirection: "column" }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormWrapFlex>
            <FormGroup>
              <FormLabel>NAME</FormLabel>
              <FormInput
                placeholder="Your Name"
                {...register("name", { required: true })}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>EMAIL</FormLabel>
              <FormInput
                placeholder="ID@jejugolf.com"
                {...register("email", { required: true, pattern: emailReg })}
              />
            </FormGroup>
          </FormWrapFlex>
          <FormGroup style={{ marginTop: "50px" }}>
            <FormLabel>MESSAGE</FormLabel>
            <FormInput
              placeholder="Say, Hi!, Jeju."
              {...register("message", { required: true })}
            />
          </FormGroup>
          <SendButton>
            SEND
            <HiMiniPlay size={25} />
          </SendButton>
        </form>
      </Section5Body>
    </Section5Wrap>
  );
};

export default Section5;
