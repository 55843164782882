import React, { useEffect, useState } from "react";

import Section1 from "../component/section1";
import Section2 from "../component/section2";

import Section4 from "../component/section4";
import Section5 from "../component/section5";
import Section1Mobile from "../component/section1Mobile";
import { isMobile } from "react-device-detect";
import Section2Mobile from "../component/section2Mobile";

import Section4Mobile from "../component/section4Mobile";
import Section5Mobile from "../component/section5Mobile";
import Section3Mobile from "../component/section3Mobile";
import Section3 from "../component/section3";
import HelmetMeta from "../utils/commonHelmet";
import Sectionevent from "../component/sectionevent";
import styled from "styled-components";
import popImg from "../assets/img/jibs_event_pop.jpg";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

const MainPage = ({ curIndex }) => {
  const navi = useNavigate();
  const [close, setClose] = useState(false); // 닫기
  const [rangeClose, setRangeClose] = useState(false);
  const [hasCookie, setHasCookie] = useState(true);
  const [cookies, setCookies] = useCookies();

  // 팝업 닫기
  const handleClose = () => setClose(true);
  const handleRangeClose = () => setRangeClose(true);

  // 팝업 만료 시간 설정
  const getExpiredDate = (days) => {
    const date = new Date();
    date.setDate(date.getDate() + days);
    return date;
  };

  useEffect(() => {
    if (cookies["POP_CLOSE"]) {
      setHasCookie(true);
    } else setHasCookie(false);
  }, []);

  useEffect(() => {
    if (!close) return;
    setHasCookie(true);
  }, [close]);

  useEffect(() => {
    if (!rangeClose) return;
    const expires = getExpiredDate(1);
    setCookies("POP_CLOSE", true, { path: "/", expires });
    setHasCookie(true);
  }, [rangeClose]);

  return (
    <>
      <HelmetMeta
        title={"Jeju Golf"}
        description={"제주골프를 100% 즐기는 법! 제주골프와 함께!"}
        imagesrc={"/share_img.png"}
        url={"https://jejugolf.kr"}
      />
      {/* {!hasCookie && (
        <PopStyle $isMobile={isMobile}>
          <div className={"popbody"}>
            <img src={popImg} onClick={() => navi("/event")} />
            <div className={"popupBtnArea"}>
              <button type={"button"} onClick={handleRangeClose}>
                24시간 동안 보이지 않기
              </button>
              <button type={"button"} onClick={handleClose}>
                닫기
              </button>
            </div>
          </div>
        </PopStyle>
      )} */}
      {isMobile ? <Section1Mobile /> : <Section1 />}
      {/* <Sectionevent /> */}
      {isMobile ? <Section2Mobile /> : <Section2 />}
      {isMobile ? (
        <Section3Mobile curIndex={curIndex} />
      ) : (
        <Section3 curIndex={curIndex} />
      )}
      {isMobile ? (
        <Section4Mobile curIndex={curIndex} />
      ) : (
        <Section4 curIndex={curIndex} />
      )}
      {isMobile ? (
        <Section5Mobile curIndex={curIndex} />
      ) : (
        <Section5 curIndex={curIndex} />
      )}
    </>
  );
};

const PopStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* width: 100vw;
  height: 100%; */
  display: flex;
  justify-content: center;

  z-index: 11112222;

  ${(props) =>
    props.$isMobile &&
    `
  background-color:#00000082;
  align-items: center;
  `}
  /* background-color: #00000082; */

  > .popbody {
    position: relative;
    background-color: white;
    display: block;
    /* display: block; */
    /* width: 100%; */
    width: 500px;
    height: 700px;
    overflow: hidden;
    border-radius: 30px;

    transform: translate(60%, 20%);
    ${(props) =>
      props.$isMobile &&
      `
  width:calc(100% - 100px);
  height:unset;
  transform: unset;
  
  `}
    /* height: 100%; */
    /* height: 650px; */
    

    > img {
      width: 100%;
      display: block;
    }
    > .popupBtnArea {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: calc(700px - 650px);

      button {
        border: none;
        cursor: pointer;
        height: calc(700px - 650px);
        font-size: 15px;
        &:first-child {
          flex: 2;
          border-right: 1px solid #03153a;
        }
        &:last-child {
          flex: 1;
        }
      }
    }
  }
`;

export default MainPage;
