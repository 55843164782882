import styled from "styled-components";

const AppPrivacyPage = () => {
  return (
    <PrivacyWrap>
      <PrivacyBody>
        <p>개인정보처리방침</p>
        <ul>
          <li className="header">1.개인정보처리방침이란?</li>
          <li>
            제주골프(이하 "회사")는 이용자의 '동의를 기반으로 개인정보를
            수집/이용 및 제공' 하고 있으며, 이용자의 권리(개인정보 자기결정권)를
            적극적으로 보장 합니다.
          </li>
          <li>
            회사는 정보통신서비스제공자가 준수하여야 하는 대한민국의 관계법령 및
            개인정보보호 규정, 가이드라인을 준수하고 있습니다.
          </li>
          <li>
            “개인정보 처리방침”이란 이용자의 소중한 개인정보를 보호함으로써
            이용자가 안심하고 서비스를 이용할 수 있도록 회사가 준수해야 할
            지침을 의미합니다.
          </li>
          <li>
            본 개인정보 처리방침은 제주골프 서비스(이하 ‘서비스’라 함)에
            적용됩니다.
          </li>
          <li className="header">2.개인정보 수집 및 이용 목적</li>
          <li>
            회사는 개인정보를 다음의 목적을 위해 수집합니다. 수집한 개인정보는
            다음의 목적 이외의 용도로는 사용되지 않으며 이용 목적이 변경될
            시에는 사전 동의를 구할 예정입니다.
          </li>
          <li className="section">홈페이지 회원가입 및 관리</li>
          <li>
            회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별 인증,
            회원자격 유지 관리, 서비스 부정이용 방지, 만14세 미만 아동 개인정보
            수집 시 법정대리인 동의 여부 확인, 서비스의 원활한 운영에 지장을
            주는 행위(계정 도용 및 부정 이용 행위 등 포함)에 대한 방지 및 제재,
            각종 고지 통지, 고충처리, 분쟁 조정을 위한 기록 보존 등 목적
          </li>
          <li className="section">민원사무 처리</li>
          <li>
            민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락 통지,
            처리결과 통보 등을 목적
          </li>
          <li className="section">재화 또는 서비스 제공</li>
          <li>
            친구에게 활동내역을 알리거나 이용자 검색, 등록 등의 기능 제공,
            유료서비스 이용 시 컨텐츠 등의 전송이나 배송·요금 정산, 서비스
            제공에 관한 계약 이행 및 서비스 제공에 따른 이용 정산(물품 배송 또는
            청구서 발송, 금융거래 본인 인증 및 금융 서비스, 구매 및 요금 결제
            요금 추심), 콘텐츠 제공, 요금결제 정산, 채권추심, 기타 입력 등 목적
          </li>
          <li className="section">마케팅 및 광고에의 활용</li>
          <li>
            신규 서비스(제품) 개발, 이벤트 및 광고성 정보 제공 및 참여기회 제공,
            인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성
            확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계, 서비스 이용
            시 장바구니 내역, 상품 선호도 등 이용패턴에 따른 맞춤 서비스
            제공(제주골프 홈페이지 및 앱) 등 목적
          </li>
          <li className="header">3. 수집하는 개인정보 처리에 관한 사항</li>
          <li>
            회사는 서비스 제공을 위해 필요한 최소한의 개인정보를 수집하고
            있습니다.
          </li>
          <li>필수정보란? : 해당 서비스의 본질적 기능을 수행하기 위한 정보</li>
          <li>
            선택정보란? : 보다 특화된 서비스를 제공하기 위해 추가 수집하는 정보
            (선택 정보를 입력하지 않은 경우에도 서비스 이용 제한은 없습니다.)
          </li>
          <li>서비스별 수집하는 개인정보 처리에 관한 사항</li>
          <li className="section">[ 제주골프예약 ]</li>
          <li>
            <PrivacyTable>
              <thead>
                <tr>
                  <td rowSpan={2}>개인정보수집목적</td>
                  <td colSpan={2}>수집하는 개인정보 항목</td>
                  <td rowSpan={2}>개인정보 보유 및 이용기간</td>
                </tr>
                <tr>
                  <td>필수</td>
                  <td>선택</td>
                </tr>
              </thead>
              <tbody>
                {/* 회원가입 */}
                <tr>
                  <td>회원가입</td>
                  <td>프로필정보(닉네임/프로필사진)</td>
                  <td>-</td>
                  <td>회원 탈퇴 시 즉시 삭제</td>
                </tr>
                {/* 휴대폰 */}
                <tr>
                  <td>휴대폰</td>
                  <td>이름, 생년월일, DI, 성별, 휴대전화번호, CI</td>
                  <td>-</td>
                  <td>회원 탈퇴 시 즉시 삭제</td>
                </tr>
                <tr>
                  <td>카드 본인인증</td>
                  <td>이름, 생년월일, DI, 성별, 휴대전화번호, CI</td>
                  <td>-</td>
                  <td>회원 탈퇴 시 즉시 삭제</td>
                </tr>
                <tr>
                  <td>고객문의 대응</td>
                  <td>예약자명, 휴대전화번호, 이메일 주소</td>
                  <td>-</td>
                  <td>3년 보관 후 삭제</td>
                </tr>
                <tr>
                  <td>환불 처리</td>
                  <td>예금주명, 은행명, 계좌번호</td>
                  <td>-</td>
                  <td>환불 후 즉시 파기</td>
                </tr>
                <tr>
                  <td>이벤트 경품 발송</td>
                  <td>이름, 주소, 휴대전화번호</td>
                  <td>-</td>
                  <td>이벤트 종료 후 즉시 파기</td>
                </tr>
                <tr>
                  <td>제세공과금처리</td>
                  <td>
                    신분증 사본 (주민등록증, 운전면허증, 여권, 외국인등록증)
                  </td>
                  <td>-</td>
                  <td>이벤트 종료 후 즉시 파기</td>
                </tr>
                <tr>
                  <td>광고 및 마케팅활용</td>
                  <td>-</td>
                  <td>서비스 이용내역, 서비스 내 결제내역</td>
                  <td>마케팅 수신 동의 철회 또는 회원 탈퇴 시 즉시 삭제</td>
                </tr>
                <tr>
                  <td>제휴문의</td>
                  <td>제안자명, 전화번호, 기업명, 메일주소</td>
                  <td>-</td>
                  <td>검토 완료 3개월 보관 후 파기</td>
                </tr>
              </tbody>
            </PrivacyTable>
          </li>
          <li>
            * 개인정보 수집 및 이용 시, 개인정보의 보유 및 이용기간은 서비스
            제공기간에 준용합니다. 단, 관계법령의 규정에 의하여 보존할 필요가
            있거나 사전 동의를 득한 경우에는 해당 보유 기간을 준용합니다.
          </li>
          <li>
            *회원 가입 시 수집한 개인정보는 계정 삭제 즉시 지체없이 파기됩니다.
            단, 적합성 검토 시 기업 회원 가입이 불가능하다고 판단되는 경우에도
            즉시 파기 처리됩니다.
          </li>
          <li className="header">
            4. 만 14세 미만 아동의 개인정보 처리에 관한 사항
          </li>
          <li>
            회사는 원칙적으로 만 14세 미만 아동에 대한 개인정보를 수집하지 않고
            있습니다. 일부 서비스에 한하여 만 14세 미만 아동의 개인정보를
            수집하고 있으며, 만 14세 미만 아동의 개인정보 수집 시 법정대리인의
            동의를 얻어 해당 서비스 수행에 필요한 최소한의 개인정보만을 수집하고
            있습니다.
          </li>
          <li>- 해당되는 서비스 : 제주골프 예약서비스 및 제주골프 홈페이지</li>
          <li>
            - 만 14세 미만 보호자 동의를 위하여 수집되는 개인정보 항목 : [필수]
            보호자 이름, 생년월일, 성별, 중복가입정보(DI)
          </li>
          <li>
            회사는 만 14세 미만 아동의 개인정보를 수집하는 경우, 아래 방법으로
            법정대리인의 동의를 확인합니다.
          </li>
          <li>
            - 법정대리인의 휴대전화 본인인증을 통해 본인여부를 확인하는 방법
          </li>
          <li>
            - 법정대리인에게 동의내용이 적힌 서면을 제공하여 서명날인 후
            제출하게 하는 방법
          </li>
          <li>
            - 그 밖에 위와 준하는 방법으로 법정대리인에게 동의내용을 알리고
            동의의 의사표시를 확인하는 방법
          </li>
          <li className="header">5. 개인정보의 제3자 제공</li>
          <li>
            회사는 고객의 개인정보를 제2조, 제3조에서 고지한 범위 내에서
            사용하며, 동 범위를 초과하여 이용하거나 타인 또는 타기관에 제공하지
            않습니다.
          </li>
          <li>
            다만, 아래 각 호와 같이 법률에 특별한 규정이 있는 경우나 법령 상
            의무를 준수하기 위해 불가피한 경우에는 예외로 합니다.
          </li>
          <li className="section">1) 이용자가 사전에 동의한 경우</li>
          <li className="section">
            2) 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와
            방법에 따라 수사기관의 요구가 있는 경우
          </li>
          <li className="section">
            3) 통계 작성, 학술연구, 시장 조사, 정보 제공 및 공지 안내 메일
            발송의 경우로서 특정 개인을 식별할 수 없는 형태로 제공되는 경우
          </li>
          <li className="section">
            4) 정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나
            주소불명 등으로 사전동의를 받을 수 없는 경우로서 정보주체 또는
            제3자의 급박한 생명, 신체, 재산의 이익을 위해 필요한 경우
          </li>
          <li className="header">6. 개인정보의 파기에 관한 사항</li>
          <li>
            회사는 개인정보는 수집 및 이용 목적이 달성되거나, 이용자의 탈퇴
            요청이 있는 경우 지체 없이 파기합니다.
          </li>
          <li>
            - 회원의 경우 개인정보의 보유 및 이용 기간은 원칙적으로 서비스
            이용계약 체결(회원가입 시)부터 서비스 이용계약 해지(탈퇴신청 시)까지
            입니다. 다만, 관계 법령의 규정에 따라 보존할 필요성이 있거나 내부
            방침 상 보관이 필요한 경우에는 목적 달성에 필요한 최소한의 기간 및
            항목만을 보관합니다.
          </li>
          <li>관련 법령에 의한 개인정보 보유 기간은 다음과 같습니다.</li>
          <li>계약 또는 청약철회 등에 관한 기록 : 5년</li>
          <li>
            (전자상거래 등에서의 소비자보호에 관한 법률 제6조 및 동시행령 제6조)
          </li>
          <li>대금결제 및 재화 등의 공급에 관한 기록 : 5년</li>
          <li>
            (전자상거래 등에서의 소비자보호에 관한 법률 제6조 및 동시행령 제6조)
          </li>
          <li>원천징수 사무처리 거래에 관한 장부 및 증거서류 : 5년</li>
          <li>(국세기본법 제85조의3)</li>
          <li>소비자의 불만 또는 분쟁처리에 관한 기록 : 3년</li>
          <li>
            (전자상거래 등에서의 소비자보호에 관한 법률 제6조 및 동시행령 제6조)
          </li>
          <li>표시/광고에 관한 기록 : 6개월</li>
          <li>
            (전자상거래 등에서의 소비자보호에 관한 법률 제6조 및 동시행령 제6조)
          </li>
          <li>컴퓨터통신 또는 인터넷 접속자료 : 3개월</li>
          <li>(통신비밀보호법 제15조의2 및 동시행령 41조)</li>
          <li>
            회사는 ‘개인정보 유효기간제’에 따라 1년 이상 서비스를 이용하지 않은
            회원의 개인정보를 별도로 분리 보관하고 있으며, 대상자에게는 분리보관
            처리일을 기준으로 최소 30일 이전에 전자우편, 알림톡 등의 방법으로
            알립니다. 분리보관된 개인정보는 4년간 보관 후 지체없이 파기합니다.
          </li>
          <li>회사의 개인정보 파기 절차 및 방법은 다음과 같습니다.</li>
          <li className="section">- 파기절차</li>
          <li>
            회사는 개인정보 처리목적이 달성된 개인정보를 지체없이 파기합니다.
          </li>
          <li className="section">- 파기방법</li>
          <li>
            전자적 파일 형태인 경우 복구 및 재생되지 않도록 안전하게 삭제하고,
            그 밖에 기록물, 인쇄물, 서면 등의 경우 분쇄하거나 소각하여
            파기합니다.
          </li>
          <li className="header">7. 개인위치정보의 처리</li>
          <li>
            위치정보의 보호 및 이용 등에 관한 법률에 따라 이용자의
            개인위치정보를 안전하게 관리합니다.
          </li>
          <li className="section">개인위치정보의 처리목적 및 보유기간</li>
          <li>
            회사가 위치기반서비스를 제공하기 위해 개인위치정보를 처리하는
            서비스와 이에 대한 처리목적 및 보유기간은 아래와 같습니다.
          </li>
          <li className="section">제주골프예약 (이용목적 달성 시)</li>
          <li>
            - 정보검색 요청 시 개인위치정보주체의 현 위치를 이용하여 주변 골프장
            검색 결과 제공
          </li>
          <li>
            - 개인위치정보 주체가 설정한 출발지 위치 정보에 따른 마케팅·광고
            제공
          </li>
          <li>
            - 현 위치를 활용하여 골프장 도착 알림 서비스과 해당 골프장 정보
            서비스 제공
          </li>
          <li>
            * 회사는 위치기반서비스 제공을 위하여 개인위치정보를 일회성 또는
            임시로 이용 후 지체없이 파기하고 있습니다. 다만, 서비스 제공을
            위하여 개인위치정보의 보관이 불가피한 경우 이용목적 달성 시 지체없이
            파기하고 있습니다.
          </li>
          <li className="section">
            개인위치정보의 수집•이용•제공사실 확인자료의 보유근거 및 보유기간
          </li>
          <li>
            회사는 위치정보의 보호 및 이용 등에 관한 법률 제16조 제2항에
            근거하여 이용자의 수집•이용•제공 사실 확인자료를 위치정보시스템에
            자동 기록하며, 이를 6개월 이상 보관하고 있습니다.
          </li>
          <li className="section">개인위치정보의 파기 절차 및 방법</li>
          <li>
            회사는 개인위치정보의 처리목적이 달성된 경우, 개인위치정보를 재생이
            불가능한 방법으로 안전하게 파기하고 있습니다. 전자적 파일 형태는
            복구 및 재생이 불가능한 기술적인 방법으로 파기하며, 출력물 등은
            분쇄하거나 소각하는 방식으로 파기합니다.
          </li>
          <li className="section">개인위치정보의 제3자 제공에 관한 사항</li>
          <li>
            회사는 이용자의 사전 동의 없이 개인위치정보를 제3자에게 제공하지
            않으며, 이용자가 지정한 제3자 에게 개인위치정보를 제공하는 경우 매회
            개인위치정보주체에게 제공받는자, 제공일시 및 제공목적을 즉시
            통보하고 있습니다.
          </li>
          <li className="section">개인위치정보의 보호조치에 관한 사항</li>
          <li>
            회사는 이용자의 개인위치정보를 안전하게 보호하기 위한 보호조치를
            마련 및 적용하고 있습니다.
          </li>
          <li className="section">8세 이하의 아동 등의 보호의무자의 권리</li>
          <li>
            회사는 아래의 경우에 해당하는 자(이하 “8세 이하의 아동”등이라
            한다)의 보호의무자가 8세 이하의 아동 등의 생명 또는 신체보호를
            위하여 개인위치정보의 이용 또는 제공에 동의하는 경우에는 본인의
            동의가 있는 것으로 봅니다.
          </li>
          <li className="section">- 8세 이하의 아동</li>
          <li className="section">- 피성년후견인</li>
          <li className="section">
            - 장애인복지법 제2조제2항제2호의 규정에 의한 정신적 장애를 가진
            자로서 「장애인 고용촉진 및 직업 재활법」 제2조제2호의 규정에 의한
            중증장애인에 해당하는 자(장애인복지법 제32조의 규정에 의하여
            장애인등록을 한 자에 한한다.)
          </li>
          <li>
            8세 이하의 아동 등의 생명 또는 신체의 보호를 위하여 개인위치정보의
            이용 또는 제공에 동의를 하고자 하는 보호의무자는 서면동의서에
            보호의무자임을 증명하는 서면을 첨부하여 회사에 제출하여야 합니다.
          </li>
          <li>
            보호의무자는 8세 이하의 아동 등의 개인위치정보 이용 또는 제공에
            동의하는 경우 개인위치정보주체 권리의 전부를 행사할 수 있습니다.
          </li>
          <li className="section">위치정보 관리책임자의 정보</li>
          <li>
            회사의 위치정보 관리책임자는 개인정보 보호책임자가 겸직하고
            있습니다.
          </li>
          <li className="header">8. 이용자 및 법정대리인의 권리와 행사방법</li>
          <li>제주골프는 여러분의 권리를 보호합니다.</li>
          <li>
            이용자는 언제든지 자신의 개인정보(만 14세 미만인 경우 법정 대리인)를
            열람(조회)하거나 수정⋅삭제할 수 있으며, 개인정보의 처리정지, 동의
            철회 또는 회원 탈퇴를 요청할 수 있습니다.
          </li>
          <li>
            보다 구체적으로는 서비스 내 설정 기능을 통한 변경, 가입 해지(동의
            철회)를 위해서는 서비스 내 '계정삭제'를 클릭하면 되며, 고객센터를
            통해 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.
          </li>
          <li>
            제주골프는 이용자의 요청을 받은 경우 이를 지체없이 처리하며,
            이용자가 개인정보의 오류에 대한 정정을 요청한 경우 정정을 완료하기
            전까지 해당 개인정보를 이용 또는 제공하지 않습니다.
          </li>
          <li>
            만 14세 미만 아동의 법정대리인은 아동의 개인정보를 열람(조회)하거나
            수정 및 삭제, 처리정지, 수집 및 이용, 제공 동의를 철회할 권리를
            가집니다.
          </li>
          <li className="header">9. 개인정보의 안전성 확보조치에 관한 사항</li>
          <li>
            회사는 이용자의 개인정보를 가장 소중한 가치로 여기고 개인정보를
            취급함에 있어서 다음과 같은 노력을 다하고 있습니다.
          </li>
          <li className="section">개인정보의 암호화</li>
          <li>
            회사는 이용자의 개인정보를 암호화된 통신구간을 이용하여 전송하고,
            비밀번호 등 중요정보는 암호화하여 보관하고 있습니다.
          </li>
          <li className="section">접속 기록의 위∙변조 방지</li>
          <li>
            개인정보처리시스템에 접속한 기록을 보관∙관리하며, 접속 기록이 위변조
            및 도난, 분실되지 않도록 접속 기록을 안전하게 보관하고 있습니다.
          </li>
          <li className="section">접근 통제 장치의 설치 및 운영</li>
          <li>
            이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 외부로부터
            접근이 통제된 구역에 시스템을 설치하고 있습니다. 전산실과 자료
            보관실 등을 보호구역으로 지정하여 출입을 통제하고 있습니다.
          </li>
          <li className="section">해킹 예방 대책</li>
          <li>
            해커 등의 침입을 탐지하고 차단할 수 있는 시스템을 설치하여 24시간
            감시하고 있으며, 백신 프로그램을 설치하여 시스템이 최신 악성코드나
            바이러스에 의한 피해를 방지하고 있습니다.
          </li>
          <li>
            새로운 해킹/보안 기술에 대해 지속적으로 연구하여 서비스에 적용하고
            있습니다.
          </li>
          <li className="section">개인정보 취급 직원의 최소화 및 교육</li>
          <li>
            개인정보취급자를 최소한으로 유지하고 보안서약서를 작성하게 함으로써
            사람에 의한 정보 유출을 사전에 방지하고 있습니다.
          </li>
          <li>
            개인정보를 취급하는 모든 임직원들을 대상으로 개인정보보호 의무와
            보안에 대한 정기적인 교육을 실시하고 있습니다.
          </li>
          <li>
            개인정보를 보관하는 데이터베이스 시스템과 개인정보를 처리하는
            시스템에 대한 비밀번호의 생성과 변경, 그리고 접근할 수 있는 권한에
            대한 체계적인 기준을 마련하고 지속적인 감사를 실시하고 있습니다.
          </li>
          <li className="header">
            10. 개인정보 자동 수집 장치에 관한 사항 (설치·운영 및 거부)
          </li>
          <li>
            PC 기반 서비스의 제공을 위하여 쿠키를 이용하는 경우가 있습니다.
          </li>
          <li>
            쿠키는 보다 빠르고 편리한 웹사이트 사용을 지원하고 맞춤형 서비스를
            제공하기 위해 사용됩니다.
          </li>
          <li>쿠키란?</li>
          <li>
            이용자가 웹사이트를 접속할 때 해당 웹사이트에서 이용자의 브라우저에
            보내는 아주 작은 텍스트 파일로 이용자 PC에 저장됩니다.
          </li>
          <li className="section">사용목적</li>
          <li>
            개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를
            저장하고 수시로 불러오는 쿠키를 사용합니다. 이용자가 웹사이트에
            방문할 경우 웹 사이트 서버는 이용자의 디바이스에 저장되어 있는
            쿠키를 읽어 이용자의 환경설정을 유지하고 맞춤화된 서비스를 제공하게
            됩니다. 쿠키는 이용자가 웹 사이트를 방문할 때, 웹 사이트 사용을
            설정한대로 접속하고 편리하게 사용할 수 있도록 돕습니다.
          </li>
          <li className="section">쿠키 수집 거부</li>
          <li>
            이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 이용자는 웹
            브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나 쿠키가
            저장될 때마다 확인을 거치거나 모든 쿠키의 저장을 거부할 수도
            있습니다.
          </li>
          <li>
            다만, 쿠키 설치를 거부할 경우 웹 사용이 불편해지며, 로그인이 필요한
            일부 서비스 이용에 어려움이 있을 수 있습니다.
          </li>
          <li className="section">- 설정 방법</li>
          <li>Internet Explorer의 경우</li>
          <li>
            {"웹 브라우저 상단의 도구 메뉴 > 인터넷 옵션 > 개인정보 > 설정"}
          </li>
          <li>Microsoft Edge의 경우</li>
          <li>
            {
              "웹 브라우저 우측의 설정 메뉴 > 설정 > 쿠키 및 사이트 권한 > 쿠키 및 사이트 데이터 관리 및 삭제"
            }
          </li>
          <li>Chrome의 경우</li>
          <li>
            {
              "웹 브라우저 우측의 설정 메뉴 > 웹 브라우저 우측의 설정 메뉴 > 개인정보보호 및 보안 > 쿠키 및 기타 사이트 데이터"
            }
          </li>
          <li className="header">11. 행태정보의 관리</li>
          <li>
            이용자의 웹사이트 방문 기록, 이용 형태를 통해서 최적화된 광고 등
            맞춤형 정보를 제공하기 위해 활용됩니다.
          </li>
          <li>
            '온라인 맞춤형 광고 개인정보보호 가이드 라인'에 따른 이용자
            권리보장을 위한 페이지를 제공하고 있습니다. (온라인 맞춤형 광고 안내
            페이지)
          </li>
          <li className="header">12. 추가적인 이용·제공 판단기준</li>
          <li>
            제주골프는 법령에 따라 정보주체의 동의없이 개인정보를 추가적으로
            이용·제공할 수 있습니다. 정보주체의 동의 없이 추가적인 이용·제공을
            하기 위해서 다음과 같은 사항을 고려하고 있습니다.
          </li>
          <li>
            개인정보를 추가적으로 이용·제공하려는 목적이 당초 수집 목적과
            관련성이 있는지 여부
          </li>
          <li>
            개인정보를 수집한 정황 등에 비추어 볼 때 추가적인 이용·제공에 대한
            예측 가능성이 있는지 여부
          </li>
          <li>
            개인정보의 추가적인 이용·제공이 정부주체의 이익을 부당하게
            침해하는지 여부
          </li>
          <li>
            가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부
          </li>
          <li className="header">13. 개인정보 보호책임자 및 고충처리 부서</li>
          <li>
            제주골프는 이용자의 개인정보 관련 문의사항 및 불만 처리 등을 위하여
            개인정보 보호책임자 및 고충처리 부서를 지정하고 있습니다.
          </li>
          <li>
            서비스를 이용하면서 발생하는 모든 개인정보보호 관련 문의, 불만,
            조언이나 기타 사항은 개인정보 보호책임자 및 개인정보 보호부서로
            연락해 주시기 바랍니다. 제주골프는 여러분의 목소리에 귀 기울이고
            신속하고 충분한 답변을 드릴 수 있도록 최선을 다하겠습니다.
          </li>
          <li className="section">개인정보 보호책임자 및 개인정보 보호부서</li>
          <li>책임자 : 이 창 섭</li>
          <li>직책 : 관리이사</li>
          <li>연락처 : 고객센터 070-4231-8542 / 이메일 : golfrgo@naver.com</li>
          <li>개인정보침해 신고센터 (한국인터넷진흥원 운영)</li>
          <li>(국번없이)118</li>
          <li>https://privacy.kisa.or.kr</li>
          <li>개인정보 분쟁조정위원회</li>
          <li>(국번없이)1833-6972</li>
          <li>https://www.kopico.go.kr</li>
          <li>대검찰청 사이버수사단</li>
          <li>(국번없이)1301</li>
          <li>https://www.spo.go.kr</li>
          <li>경찰청 사이버수사국</li>
          <li>(국번없이)182</li>
          <li>https://ecrm.police.go.kr/</li>
          <li className="header">16. 개정 전 고지의무 등 안내</li>
          <li>
            법령이나 서비스의 변경사항을 반영하기 위한 목적 등으로 개인정보
            처리방침을 수정할 수 있습니다. 개인정보 처리방침이 변경되는 경우
            최소 7일 전 변경 사항을 사전에 안내하겠습니다.
          </li>
          <li>
            다만, 수집하는 개인정보의 항목, 이용목적의 변경 등과 같이 이용자
            권리의 중대한 변경이 발생할 때에는 최소 30일 전에 미리
            알려드리겠습니다.
          </li>
          <li>
            제주골프는 이용자 여러분의 정보를 소중히 생각하며, 이용자가 더욱
            안심하고 서비스를 이용할 수 있도록 최선의 노력을 다할것을
            약속드립니다.
          </li>
          <li>- 공고일자: 2024년 03월 01일</li>
          <li>- 시행일자: 2024년 03월 01일</li>
        </ul>
      </PrivacyBody>
    </PrivacyWrap>
  );
};

const PrivacyWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;

  padding: 1em;
  z-index: 111111111;
  overflow-y: scroll;
`;

const PrivacyBody = styled.div`
  position: relative;
  box-shadow: 0px 0px 0px 10px #29000000;

  p {
    font-size: 1.5em;
  }

  ul {
    margin: 0;
    padding: 0;
  }
  li {
    padding: 0;
    list-style: none;
    margin: 0;
    font-size: 0.5em;
    word-break: keep-all;
    padding-bottom: 0.5em;

    &.header {
      font-weight: 600;
      font-size: 0.8em;
      padding-top: 1em;
      padding-bottom: 1em;
    }

    &.section {
      font-weight: 500;
      font-size: 0.65em;
      padding: 0.65em 0;
    }
  }
`;

const PrivacyTable = styled.table`
  border: 1px solid black;
  border-collapse: collapse;

  text-align: center;

  td {
    padding: 0.5em;
    border: 1px solid black;
    border-collapse: collapse;
  }
`;

export default AppPrivacyPage;
