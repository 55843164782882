import styled from "styled-components";
import { BREAK_POINT } from "./common/windowSize";

export const Section1Wrap = styled.div`
  position: relative;
  display: flex;
  font-family: "Nanum";
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 920px;
  background-color: white;
  border-bottom-left-radius: 300px;
  z-index: 10;
  overflow-y: hidden;
  /* clip: auto; */
`;
export const S1VideoWrap = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 920px;
  overflow-y: hidden;
  /* width: 100%; */
  /* height: 100%; */

  &::before {
    content: "";
    position: absolute;

    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #000;
  }
`;
export const S1Video = styled.video`
  position: relative;
  opacity: 0.4;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 920px;
`;

export const SectionContents = styled.div`
  position: relative;
  /* max-width: 1905px; */
  /* margin: 0 auto; */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s linear;
  transform: translateY(${(props) => (props.$init ? "0" : "-10px")});
`;
export const Section1TextCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 74px 0;
  width: calc(100vw - 640px);
  transition: opacity 0.5s linear;
  opacity: ${(props) => (props.$init ? "1" : "0")};

  @media (max-width: ${BREAK_POINT.section1.main_contents}px) {
    width: 640px;
    margin: 0 auto;
    align-items: end;
    text-align: right;
  }
`;

export const Section1TextSpan = styled.span`
  color: white;
  /* width: calc(100% - 640px); */
  font-weight: 700;
  font-size: 2.2em;
  line-height: 80px;
  letter-spacing: -2.75px;
`;

export const Section1TextSubSpan = styled(Section1TextSpan)`
  font-size: 1em;
  font-weight: bold;
  line-height: 50px;
  letter-spacing: -0.75px;
`;

export const Section1Img = styled.img`
  position: absolute;
  width: 100%;
  max-width: 781px;
  max-height: 608px;
  right: -50px;
  top: 40px;
  transition: opacity 0.5s linear;
  opacity: ${(props) => (props.$init ? "0.8" : "0")};
  z-index: -1;

  @media (max-width: ${BREAK_POINT.section1.mb_crash_size}px) {
    opacity: 0.3;
    right: 0px;

    z-index: -1;
  }
`;

export const Section1BtnWarp = styled.div`
  padding-top: 100px;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0 20px;
`;
export const Section1BtnItem = styled.button`
  position: relative;
  padding: 23px 50px;
  min-width: 190px;
  border-radius: 38px;
  border: 2px solid white;
  background-color: ${(props) => (props.$isOutline ? "transparent" : "white")};
  color: ${(props) => (props.$isOutline ? "white" : "#00c480")};
  letter-spacing: -0.9px;
  font-size: 18px;
  line-height: 36px;
  font-weight: bold;
  cursor: pointer;

  .untoggle {
    display: block;
    opacity: ${(props) => (props.$toggle ? "0" : "1")};
    transition: all 0.3s linear;
  }
  .toggle {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    opacity: ${(props) => (props.$toggle ? "1" : "0")};
    transition: all 0.3s linear;
  }
`;
