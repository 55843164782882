import styled from "styled-components";
import { customAxios } from "../axios/axios.config";
import { useEffect, useState } from "react";
import { PulseLoader } from "react-spinners";
import { HiRefresh } from "react-icons/hi";
import CustomTimer from "../component/event/customTimer";

const TempCnt = () => {
  const [cnt, setCnt] = useState(0);
  const [curDate, setCurDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const refresh = () => {
    setCurDate(new Date());
    getCnt();
  };

  const getCnt = () => {
    setLoading(true);
    customAxios
      .get("/user/event/cnt")
      .then((value) => {
        if (value.status == 200) {
          setCnt(value.data["data"]);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
        return;
      });
  };

  useEffect(() => {
    getCnt();
  }, []);
  return (
    <Wrap>
      <div className="cntBody">
        <p>현재 이벤트 신청자 수</p>
        <div style={{ paddingBottom: "20px" }}>
          {loading ? (
            <PulseLoader />
          ) : (
            <span className={"cnt"}>{cnt} 명 입니다</span>
          )}
        </div>
        <div className={"curDateArea"}>
          <span className={"curDateTime"}>
            <small>{curDate.toLocaleDateString()}</small>
            <small>{curDate.toLocaleTimeString()}</small>
            <small>{" 기준"}</small>
          </span>
        </div>
        <div className={"timer"}>
          <CustomTimer refresh={refresh} />
        </div>
      </div>
    </Wrap>
  );
};

export default TempCnt;

const Wrap = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #ebebeb;

  .cntBody {
    padding: 0 20px;
    width: 100%;

    text-align: center;

    p,
    span {
      letter-spacing: -0.75px;

      &.curDateTime {
        /* width: 100%; */

        font-size: 0.7em;
      }
      &.cnt {
        font-weight: bold;
      }
    }

    .curDateArea {
      display: flex;
      justify-content: center;
      align-items: center;

      .refresh {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;
