import styled from "styled-components";
import { InputLabel } from "../eventForm/customFormStyle";

const AppUserPrivacy = () => {
  return (
    <PrivacyWrap>
      <PrivacyBody>
        <p>이용약관</p>
        <ul>
          <li className="header">제 1조 [목적]</li>
          <li>
            본 약관(이하 ‘약관’이라 칭함)은 이용자가 제주골프 이하 ‘회사’라
            칭함)에서 제공하는 서비스 (제주골프 예약서비스, 홈페이지 이벤트,
            온라인스토어 등)의 이용 및 인터넷관련 서비스, 웹 및 모바일 앱 서비스
            이용과 관련하여 회사와 회원과의 권리, 의무 및 책임사항, 기타 필요한
            사항을 규정함을 목적으로 합니다.
          </li>
        </ul>
        <ul>
          <li className="header">제 2조 [용어의 정의]</li>
          <li>본 약관에서 사용하는 주요 용어의 정의는 다음과 같습니다.</li>
          <li>
            “제주골프 회원제”란 하나의 제주골프 계정으로 로그인하여 회사에서
            제공하는 서비스를 이용할 수 있는 회원 서비스입니다.
          </li>
          <li>
            “서비스”란 회사에서 제공하는 제주골프 예약서비스, 이벤트, 제주골프
            온라인스토어를 말하며 제주골프 회원이면 누구나 사용하실 수 있습니다.
          </li>
          <li>
            “회원”이라 함은 회사의 서비스에 접속하여 약관에 따라 회사와
            이용계약을 체결하고 회사가 제공하는 서비스를 이용하는 고객을
            말합니다.
          </li>
          <li>
            “회원”은 회원의 식별과 서비스 이용을 위하여 회원이 ‘아이디’와
            ‘닉네임’을 통해 회사의 서비스에 가입하는 것을 의미하며, '아이디'
            또는 '닉네임'이라 함은 회원의 식별과 서비스 이용을 위하여 회원이
            정하고 회사가 승인하는 문자와 숫자의 조합을 의미합니다.
          </li>
          <li>
            “비밀번호”라 함은 회원이 부여받은 아이디와 일치되는 회원임을
            확인하고 비밀보호를 위해 회원 자신이 정한 문자 또는 숫자의 조합을
            의미합니다.
          </li>
          <li>
            “포인트”란 회사가 회원의 이벤트 활동 영역에 따라 회원에게 지급할 수
            있는 것으로 회원이 서비스를 이용할 때 현금처럼 바로 사용할 수는
            없으나, 일정금액 이상부터는 '캐시'로 전환하여 현금처럼 사용할 수
            있는 지급수단을 말합니다..
          </li>
          <li>
            “할인쿠폰”이란 상품등을 구매할 때나 서비스를 이용할 때 쿠폰에 표시된
            금액 또는 비율만큼 할인/적립을 받을 수 있는 회사 운영 매장 및 제휴사
            전용의 온라인/오프라인 쿠폰을 말합니다.
          </li>
          <li>
            “게시물”이란 회사나 회원이 서비스 사이트 및 앱내에서 게시한 글,
            사진, 각종 파일, 링크 등을 말합니다.
          </li>
          <li>
            “제휴사”란 회사와 계약을 체결하여 회원에게 상품등을 거래하거나 각종
            정보 및 혜택 등을 제공하거나 회원이 적립금을 사용, 교환할 수 있는
            온라인 및 오프라인 업체를 의미합니다. 제휴사는 회사가 제공하는 개별
            서비스 홈페이지에 고지되어 있으며, 회사 및 제휴사의 사정에 따라 해지
            또는 추가될 수 있습니다.
          </li>
          <li>
            “NFT”란 대체 불가능한 토큰(Non Fungible Token)의 약어로 이미지,
            동영상, 음성 등의 “디지털 저작물”의 데이터가 담겨 블록체인
            네트워크에서 발행되고, 고유의 속성값을 가지는 디지털 자산의 한
            종류입니다.
          </li>
          <li>
            제주골프 내에서 발급된 “NFT”는 제주골프 내에서만 활용 가능합니다.{" "}
          </li>
          <li>(예 : 캐릭터 및 골프공, 볼마커 등)</li>
          <li>
            약관에서 사용하는 용어 중 약관 제2조에서 정의되지 않은 용어의 의미는
            일반적인 거래관행에 의합니다.
          </li>
        </ul>
        <ul>
          <li className="header">제 3조 [회원제의 관리]</li>
          <li>
            회원은 제주골프 계정을 통해 회사에서 제공되는 서비스를 이용할 수
            있습니다. 단, 개별선택 약관에 동의하지 않은 일부 서비스 및 회원
            가입을 진행했을 경우, 해당 일부 서비스는 이용이 제한됩니다.
          </li>
          <li>
            회사는 특정 서비스를 제공하기 위해 회원에게 별도 또는 추가적인 가입
            절차를 요청할 수 있습니다.
          </li>
          <li>
            서비스 변경 시 회원제가 적용이 되는 각 서비스 홈페이지 및 앱 초기
            화면에 공지하거나 회원에게 전자우편, 문자메시지 또는 기타 가능한
            수단(알림톡 등)을 통해 알릴 수 있으며, 별도 약관으로 명시하지 않는
            한 본 약관이 적용됩니다.
          </li>
          <li>
            회사는 회원이 회사에서 제공되는 서비스를 원활하게 이용할 수 있도록
            회원제도를 관리하며, “서비스”를 개선, 변경할 수 있습니다.
          </li>
        </ul>
        <ul>
          <li className="header">제 4조 [이용약관 효력과 변경]</li>
          <li>
            약관은 회사에서 제공하는 서비스 사이트 및 앱에서 온라인으로
            공시함으로써 효력을 발생하며, 합리적인 사유가 발생할 경우 관련법령에
            위배되지 않는 범위 안에서 개정될 수 있습니다.
          </li>
          <li>
            개정된 약관은 온라인에서 공지함으로써 효력을 발휘하며, 이용자의 권리
            또는 의무 등 중요한 규정의 개정은 사전에 공지합니다.
          </li>
          <li>
            회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여
            현행약관과 함께 인터넷 사이트 및 모바일 어플리케이션에 그 적용일자
            7일 이전부터 적용일자 전일까지 공지합니다. 다만, 회원에게 불리하게
            약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고
            공지합니다.
          </li>
          <li>
            약관에 동의하는 것은 정기적으로 서비스 사이트 및 앱을 방문하여
            약관의 변경사항을 확인하는 것에 동의함을 의미합니다.
          </li>
          <li>
            변경된 약관에 대한 정보를 알지 못해 발생하는 이용자의 피해는
            회사에서 책임지지 않습니다.
          </li>
          <li>
            회원은 변경된 약관에 동의하지 않을 권리가 있고, 변경된 약관에
            동의하지 않을 경우 회원 탈퇴(해지)를 요청할 수 있으며, 변경된 약관의
            효력 발생일로부터 7일 이후에도 거부의사를 표시하지 아니하고 서비스를
            계속 사용할 경우 약관의 변경 사항에 동의한 것으로 간주됩니다.
          </li>
        </ul>
        <ul>
          <li className="header">제 5조 [약관 및 준칙]</li>
          <li>
            본 약관은 회사가 제공하는 서비스에 관한 이용안내에 적용합니다.
          </li>
          <li>
            약관에서 정하지 아니한 사항과 약관의 해석에 관하여는 「전자상거래
            등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」,
            공정거래위원회가 정하는 「전자상거래 등에서의 소비자 보호지침」 등
            관련 법령 및 상관례에 따릅니다.
          </li>
        </ul>
        <ul>
          <li className="header">제 6조 [이용계약의 체결]</li>
          <li>
            이용계약은 회원이 되고자 하는 자(이하 ‘가입신청자’라 칭함)가 약관의
            내용에 대하여 동의를 한 다음 회원가입신청을 하고 회사가 이러한
            신청에 대하여 승낙함으로써 체결됩니다.
          </li>
          <li>
            회사는 가입신청자의 신청에 대하여 서비스 이용을 승낙함을 원칙으로
            합니다. 다만, 회사는 다음 각 호에 해당하는 신청에 대하여는 승낙을
            하지 않거나 사후에 이용계약을 해지할 수 있습니다.
          </li>
          <li>
            가입신청자가 본 약관에 의하여 이전에 회원자격을 상실한 적이 있는
            경우. 단, 회사의 회원 재가입 승낙을 얻은 경우에는 예외로 합니다.
          </li>
          <li>실명이 아니거나 타인의 명의를 이용한 경우</li>
          <li>
            허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우
          </li>
          <li>기타 회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우</li>
          <li>
            제2항의 승낙을 함에 있어 회사는 관계법령 및 회사정책에 따라 서비스의
            이용 가능한 범위, 시간, 횟수 등에 차등을 두어 이를 승낙할 수
            있습니다.
          </li>
          <li>
            회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가
            있는 경우에는 승낙을 유보할 수 있습니다.
          </li>
          <li>
            제2항과 제4항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한
            경우, 회사는 원칙적으로 이를 가입신청자에게 알리도록 합니다.
          </li>
          <li>
            제1항 및 제2항과 관련하여 회사는 사전 또는 사후에 회원에게
            전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.
          </li>
          <li>
            이용계약의 성립 시기는 회사가 가입완료를 신청절차 상에서 표시한
            시점으로 합니다.
          </li>
          <li>
            회사는 회원에 대해 회사정책에 따라 등급별로 구분하여 이용시간,
            이용횟수, 서비스, 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.
          </li>
          <li>
            관련법령에 의거 회원가입 신청일 현재 만 14세 미만의 회원 가입이
            제한될 수 있습니다.{" "}
          </li>
        </ul>
        <ul>
          <li className="header">제 7조 [회원정보 변경]</li>
          <li>
            회원은 서비스 내 개인정보관리화면을 통하여 언제든지 본인의
            개인정보를 열람하고 수정할 수 있습니다.
          </li>
          <li>
            회원은 회원가입 신청 시 기재한 사항이 변경되었을 경우
            개인정보관리화면에서 회원정보를 수정하는 방법으로 회사에 그
            변경사항을 알려야 합니다. 변경사항을 회사에 알리지 않아 발생한
            불이익에 대하여 회사는 책임지지 않습니다.
          </li>
          <li>
            회원정보 수정 시, 변경사항은 수정을 원하시는 해당 서비스에서만
            적용되고, 다른 서비스에는 적용되지 않습니다. (단, 회사에서 관리하는
            회원정보는 수정 된 정보로 보관됩니다.)
          </li>
        </ul>
        <ul>
          <li className="header">제 8조 [개인정보 처리방침]</li>
          <li>
            회사는 개인정보보호법 등 관계 법령이 정하는 바에 따라 회원의
            개인정보를 보호하기 위해 노력하며 개인정보 수집 시 서비스 제공을
            위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.
          </li>
          <li>
            개인정보의 보호 및 사용에 대해서는 관계법령 및 회사의
            개인정보취급방침이 적용됩니다. 다만, 회사의 공식 서비스 사이트
            이외의 링크된 사이트에서는 회사의 개인정보취급방침이 적용되지
            않습니다.
          </li>
          <li>
            회사는 이용자의 개인정보를 원칙적으로 외부에 제공, 공개하지
            않습니다. 다만, 개인정보처리방침에 따라 이용자의 개인정보를 제공할
            필요가 있는 경우 해당 시점에 회원의 동의를 받아 개인정보를 제3자에
            제공할 수 있습니다. 이때 회사는 제공되는 개인정보의 항목, 제공받는
            자, 제공받는 자의 개인정보 이용목적 및 보유∙이용기간 등을 회원에게
            미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수
            있습니다.
          </li>
        </ul>
        <ul>
          <li className="header">
            제 9조 [회원의 아이디 및 비밀번호 관리 의무]
          </li>
          <li>
            회원의 아이디와 비밀번호에 관한 관리책임은 회원에게 있으며, 이를
            제3자가 이용하도록 하여서는 안 됩니다.
          </li>
          <li>
            회원은 아이디 및 비밀번호가 도용되거나 제3자가 사용하고 있음을
            인지한 경우에는 이를 즉시 회사에 통지하고 회사의 안내에 따라야
            합니다.
          </li>
          <li>
            제2항의 경우에 해당 회원이 회사에 그 사실을 통지하지 않거나, 통지한
            경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는
            책임지지 않습니다.
          </li>
          <li>
            회사의 귀책사유 없이 아이디 또는 비밀번호의 유출, 양도, 대여로
            인하여 발생하는 손실이나 손해에 대하여는 회원 본인이 책임을
            부담합니다.
          </li>
        </ul>
        <ul>
          <li className="header">제 10조 [회원계정 부여 및 변경]</li>
          <li>
            회사는 회원에 대하여 약관에 정하는 바에 따라 다음의 형태로
            이용자계정을 부여합니다.
          </li>
          <li>회원가입(제주골프 계정가입)</li>
        </ul>
        <ul>
          <li className="header">제 11조 [회사의 의무]</li>
          <li>
            회사는 관계법령 및 약관이 금지하거나 미풍양속에 반하는 행위를 하지
            않으며, 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여
            노력합니다.
          </li>
          <li>
            회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보
            포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보취급방침을
            공시하고 준수합니다.
          </li>
          <li>
            회사는 서비스이용과 관련하여 회원으로부터 제기된 의견이나 불만이
            정당하다고 인정할 경우에는 이를 처리하여야 합니다.
          </li>
          <li>
            회원이 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나
            이메일 등을 통하여 회원에게 처리과정 및 결과를 전달합니다.
          </li>
        </ul>
        <ul>
          <li className="header">제 12조 [회원의 의무]</li>
          <li>회원은 다음 각 호의 행위를 하여서는 안 됩니다.</li>
          <li>회원가입 신청 또는 변경 시 허위내용의 등록</li>
          <li>타인의 정보도용</li>
          <li>회사에 게시된 정보의 변경</li>
          <li>
            회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시
            행위
          </li>
          <li>회사와 기타 제3자의 저작권 등 지적재산권에 대한 침해 행위</li>
          <li>
            회사 및 제휴사, 기타 제3자의 명예를 손상시키거나 업무 방해, 재산상의
            손해를 끼치는 행위
          </li>
          <li>
            외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를
            회사에 공개 또는 게시하는 행위
          </li>
          <li>회사의 동의 없이 영리를 목적으로 서비스를 사용하는 행위</li>
          <li>
            골프 예약 등을 유상양도, 판매, 담보제공 등의 방법으로 처분하는 행위
          </li>
          <li>기타 불법적이거나 부당한 행위</li>
          <li>
            회원은 관계법, 본 약관의 규정, 이용안내 및 서비스와 관련하여 공지한
            주의사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타 회사의
            업무에 방해되는 행위를 하여서는 안 됩니다.
          </li>
          <li>
            회사의 서비스 환경 제공에 악영향을 끼치는 경우 각 서비스 운영정책에
            의거하여 위의 항목에 구체적으로 나열되지 않은 방법으로 이용이 제한될
            수 있습니다.
          </li>
        </ul>
        <ul>
          <li className="header">제 13조 [회원에 대한 통지]</li>
          <li>
            회사가 회원에 대한 통지를 하는 경우 약관에 별도 규정이 없는 한
            회원이 지정한 이메일 주소, 서비스 내 전자메모 등으로 할 수 있습니다.
          </li>
          <li>
            회사는 회원 전체에 대한 통지의 경우 7일 이상 회사에서 제공하는
            서비스 게시판에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.
            다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에
            대하여는 개별통지를 합니다.
          </li>
        </ul>
        <ul>
          <li className="header">제 14조 [서비스 이용시간 및 제공]</li>
          <li>
            서비스의 이용은 연중무휴 1일 24시간을 원칙으로 합니다. 다만, 회사의
            업무 상이나 기술상의 이유로 서비스가 일시 중지될 수 있고, 또한 운영
            상의 목적으로 회사가 정한 기간에는 서비스가 일시 중지될 수 있습니다.
          </li>
          <li>
            회사는 서비스를 일정범위로 분할하여 각 범위별로 이용 가능한 시간을
            별도로 정할 수 있습니다.
          </li>
          <li>회사는 회원에게 아래와 같은 서비스를 제공합니다.</li>
          <li>제주골프예약 서비스</li>
          <li>제주골프 홈페이지 이벤트</li>
          <li>제주골프 온라인스토어</li>
          <li>위치정보 서비스 (서비스 별 선택사항)</li>
          <li>보안서비스</li>
          <li>고객보호 서비스</li>
          <li>
            기타 회사가 추가 개발하거나 다른 회사와의 제휴계약 등을 통해
            회원에게 제공하는 일체의 서비스
          </li>
          <li>
            회사는 서비스 제공을 위하여 회원의 위치정보를 이용할 수 있습니다.
            회사는 위치정보를 사용하는 경우 회원에게 접근 권한(동의)을 요청하고,
            이에 따라 회원은 위치정보 사용 여부를 설정할 수 있으며 위치정보
            사용을 거부할 권리가 있습니다. 회사는 회원이 제공한 개인위치정보를
            고객의 동의 없이 서비스 제공 이외의 목적으로 이용하지 않습니다.
          </li>
          <li>
            회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절
            또는 운영상 상당한 이유가 있는 경우 서비스의 제공을 일시적으로
            중단할 수 있습니다.
          </li>
          <li>
            제5항 경우 회사는 제13조(회원에 대한 통지)에 정한 방법으로 회원에게
            통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는
            경우 사후에 통지할 수 있습니다.
          </li>
          <li>
            회사는 서비스의 제공에 필요한 경우 정기점검 또는 수시점검을 실시할
            수 있으며, 점검시간은 서비스 제공 화면에 공지한 바에 따릅니다.
          </li>
        </ul>
        <ul>
          <li className="header">제 15조 [게시물의 관리]</li>
          <li>
            회사가 작성한 게시물 또는 저작물에 대한 저작권 등의 일체의
            지적재산권은 회사에 귀속합니다.
          </li>
          <li>
            회원의 게시물이 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』,
            『개인정보 보호법』 및 『저작권법』등 관련 법령에 위반되는 내용을
            포함하는 경우, 권리자는 관련 법령이 정한 절차에 따라 해당 게시물의
            게시중단 및 삭제 등을 요청할 수 있으며, 회사는 관련 법령에 따라
            조치를 취하여야 합니다.
          </li>
          <li>
            회사는 다음 각 호에 해당하는 게시물이나 자료를 사전통지 없이
            삭제하거나 이동 또는 등록 거부를 할 수 있습니다.
          </li>
          <li>
            다른 회원 또는 제 3자에게 심한 모욕을 주거나 명예를 손상시키는
            내용인 경우
          </li>
          <li>
            공공질서 및 미풍양속에 위반되는 내용을 유포하거나 링크시키는 경우
          </li>
          <li>불법복제 또는 해킹을 조장하는 내용인 경우</li>
          <li>영리를 목적으로 하는 광고의 경우</li>
          <li>범죄와 결부된다고 객관적으로 인정되는 내용일 경우</li>
          <li>
            다른 이용자 또는 제 3자의 저작권 등 기타 권리를 침해하는 내용인 경우
          </li>
          <li>
            회사에서 규정한 게시물 원칙에 어긋나거나, 게시판 성격에 부합하지
            않는 경우
          </li>
        </ul>
        <ul>
          <li className="header">제 16조 [게시물에 대한 저작권]</li>
          <li>
            회원이 서비스 화면 내에 게시한 게시물의 저작권은 게시한 회원에게
            귀속됩니다.
          </li>
          <li>
            회사는 게시자의 동의 없이 게시물을 상업적으로 이용할 수 없습니다.
          </li>
          <li>
            회원은 서비스를 이용하여 취득한 정보를 임의 가공, 판매하는 행위 등
            서비스에 게재된 자료를 상업적으로 사용할 수 없습니다.
          </li>
          <li>
            회사는 회원이 게시하거나 등록하는 서비스 내의 내용물, 게시 내용에
            대해 제 15조 제3항 각 호에 해당된다고 판단되는 경우 사전통지 없이
            삭제, 이동하거나 등록 거부를 할 수 있습니다.
          </li>
          <li>
            회원이 탈퇴하는 경우, 탈퇴 전에 등록 또는 게재한 게시물(댓글 포함)은
            삭제되지 않습니다. 회원 탈퇴 시 회원정보가 삭제되어 작성자 본인을
            확인할 수 없게 되므로 게시물에 대한 편집 또는 삭제가 원천적으로
            불가하며, 게시물의 삭제를 원하는 회원은 반드시 회원 탈퇴 이전에 해당
            게시물을 삭제하여야 합니다.
          </li>
        </ul>
        <ul>
          <li className="header">제 17조 [정보의 제공]</li>
          <li>
            회사는 회원의 사전 동의가 있는 경우에 한하여 이메일이나 서신,
            문자메시지(SMS), 전화 등의 방법을 이용하여 회원의 서비스 이용에
            필요가 있다고 인정되는 각종 정보를 제공할 수 있습니다. 다만, 회원은
            거래관련정보 및 고객문의 등에 대한 답변을 제외하고 관련 법에 따라
            언제든지 정보 수신 동의를 철회하실 수 있으며, 이 경우 회사는 마케팅
            정보 등을 제공하는 행위를 중단합니다(단, 시스템 반영에 시차가 있을
            수 있음).
          </li>
          <li>
            회사는 서비스 개선 및 회원 대상의 서비스 소개 등의 목적으로 회원의
            동의 하에 관련 법령에 따라 추가적인 개인 정보를 수집할 수 있습니다.
          </li>
        </ul>
        <ul>
          <li className="header">제 18조 [이용계약 해지]</li>
          <li>
            회원이 이용계약을 해지하고자 하는 때에는 회원 본인이 사이트의
            회원탈퇴 메뉴를 이용해 가입 해지를 해야 합니다. 해지 시 회사에서
            제공하는 전체서비스이용계약이 해지됩니다. 다만, 다음 각호 또는 그 외
            정당한 사유가 있는 경우에 탈퇴 처리를 유보할 수 있습니다.
          </li>
          <li>
            탈퇴 의사표시를 한 자가 회원 당사자인지 여부를 확인할 수 없는 경우
          </li>
          <li>
            탈퇴 의사표시를 한 회원에 관한 거래 사기 및 분쟁 문제가 남아 있는
            경우
          </li>
          <li>
            서비스 이용계약이 해지되면, 약관, 관련 법령 및 개인정보처리방침에
            따라 회사가 해당 회원의 정보를 보유할 수 있는 경우를 제외하고, 해당
            회원 계정에 부속된 모든 데이터는 소멸됨과 동시에 복구할 수 없게
            됩니다. 다만, 이 경우에도 다른 회원이 별도로 담아갔거나 스크랩한
            게시물과 공용 게시판에 등록한 댓글 등의 게시물은 삭제되지 않으므로
            반드시 해지 신청 이전에 개인적으로 삭제하신 후 탈퇴하여야 합니다.
          </li>
          <li>
            탈퇴 시 캐시, 포인트, 쿠폰, 적립금, 포인트 소멸, 캐릭터정보 등
            회원의 모든 데이터는 소멸됨과 동시에 복구할 수 없게 됩니다.
          </li>
          <li>
            탈퇴 이후 확인사항(주문 및 배송정보 등)은 회사 고객센터에서 확인이
            가능합니다.
          </li>
          <li>서비스 별 탈퇴 불가의 경우는 아래와 같습니다.</li>
          <li>제주골프 서비스 탈퇴 불가의 경우</li>
          <li>[제주골프 홈페이지 및 앱]</li>
          <li>
            제주골프 회원이 앱 서비스 내 예약대기 또는 예약확정 상태인 매장예약
            내역이 있는 경우 회원 탈퇴가 불가합니다.
          </li>
          <li>
            제주골프 회원이 앱 서비스 내 유료로 구매한 NFT 및 럭키드로우
            아이템이 존재할 경우 회원 탈퇴가 불가하며 이메일 문의 또는 유선을
            통해서만 회원 탈퇴가 가능합니다.
          </li>
        </ul>
        <ul>
          <li className="header">제 19조 [통합계정 탈퇴 시 유의 사항]</li>
          <li>
            회원탈퇴 시 회사의 통합계정에서 탈퇴됨과 동시에 회사의 모든 서비스와
            정보가 탈퇴 및 삭제됩니다. 자세한 내용은 아래와 같습니다.
          </li>
          <li>제주골프 서비스 회원정보 삭제 내용</li>
          <li>나의 라운드 기록</li>
          <li>나의 포인트</li>
          <li>가입 시 등록한 개인정보</li>
          <li>단, 탈퇴 후 아래와 같은 정보는 삭제되지 않습니다.</li>
          <li>대회에 반영된 랭킹 정보</li>
          <li>아이디에 연계된 커뮤니티 게시물 및 댓글</li>
          <li>회원 정보</li>
          <li>캐시 및 캐시 내역</li>
          <li>포인트 및 포인트 내역 (포인트 캐시 전환 내역 포함)</li>
          <li>제주골프예약 회원정보 삭제 내용</li>
          <li>
            라운드를 완료하지 않은 예약건이 있을 경우 회원 탈퇴가 불가하며
            예약취소 또는 예약이행 후 회원탈퇴가 가능합니다.
          </li>
        </ul>
        <ul>
          <li className="header">제 20조 [서비스 이용제한]</li>
          <li>
            회사는 회원이 서비스 이용내용에 있어서 본 약관 제 12조 내용을
            위반하거나, 다음 각 호에 해당하는 경우 서비스 이용을 제한할 수
            있습니다.
          </li>
          <li>
            서비스 및 제휴사 시설 이용 중 타 이용자, 제휴사, 기타 제3자에게
            욕설, 폭언, 성희롱 등 모욕을 주는 행위나 서비스 이용 방해, 손해를
            끼치는 경우
          </li>
          <li>기타 정상적인 서비스 운영에 방해가 될 경우</li>
          <li>정보통신 윤리위원회 등 관련 공공기관의 시정 요구가 있는 경우</li>
          <li>불법 홈페이지나 상용소프트웨어나 크랙 파일을 올린 경우</li>
          <li>
            정보통신에 관한 심의규정 제8조에 어긋나는 음란물을 게재한 경우
          </li>
          <li>반국가적 행위의 수행을 목적으로 하는 내용을 포함한 경우</li>
          <li>저작권이 있는 글을 무단 복제하거나 올린 경우</li>
          <li>당일취소, 노쇼, 지속적으로 예약을 파기하는 경우</li>
          <li>내부 규정에 의하여 개인 매너 평점이 F 이하인 사용자의 경우</li>
          <li>기타 불법적이거나 부당한 행위</li>
          <li>
            상기 이용제한 규정에 따라 회원에게 서비스 이용에 대하여 별도 공지
            없이 서비스 이용의 일시 정지, 초기화, 이용계약 해지 등을 불량이용자
            처리규정에 따라 취할 수 있습니다.
          </li>
          <li>
            상기 이용제한 규정외에 각 서비스의 운영정책 위반 시 서비스 이용에
            제한이 있을 수 있습니다.
          </li>
        </ul>
        <ul>
          <li className="header">제 21조 [캐시 및 적립금, 포인트]</li>
          <li>
            회사는 회원의 구매활동, 이벤트 참여 등에 따라 회원에게 일정한 캐시
            및 적립금, 포인트를 부여할 수 있습니다. 다만, 상품구매 후 취소 또는
            반품을 할 경우 발생되는 캐시 반환 및 회수는 아래 내용으로
            적용됩니다.
          </li>
          <li>
            상품구매 시 회사가 부여한 캐시 및 적립금은 “회사”가 회수합니다.
          </li>
          <li>상품구매 시 회원이 사용한 캐시는 회원에게 반환됩니다.</li>
          <li>
            회사는 비회원에게는 캐시 및 적립금, 포인트를 부여하지 않습니다.
          </li>
          <li>
            회원은 캐시 및 적립금, 포인트를 상품 등의 구매 시 결제 수단으로
            사용할 수 있으며(포인트는 캐시로 전환하여 사용 가능), 회사는 캐시 및
            적립금, 포인트의 적립기준, 사용방법, 사용기한 및 제한에 대한 사항을
            서비스 화면에 별도로 게시하거나 통지합니다. 캐시 및 적립금, 포인트의
            사용조건에 관한 사항은 회사의 정책에 따라 달라질 수 있습니다.
          </li>
          <li>
            캐시 및 적립금, 포인트의 유효기간은 원칙적으로 전환/적립 후 항목별로
            6~12개월로 상이하며, 유효기간 동안 사용하지 않을 경우 순차적으로
            소멸됩니다. 다만, 마케팅 기타 프로모션 등을 통하여 지급되거나
            사전특약(사전 안내 포함)이 있는 캐시 및 적립금의 유효기간은 각각
            별도로 설정될 수 있습니다.
          </li>
          <li>
            캐시 및 적립금, 포인트의 사용은 1) 유효기간 종료일이 먼저 도래하는
            순서, 2) 유효기간이 동일한 경우 적립일자가 빠른 순서로 순차적으로
            사용됩니다. 3) 단, 제주골프 온라인스토어 주문 시 사용한 캐시는
            부분환불 시 반환되지 않습니다.
          </li>
          <li>
            캐시 및 적립금, 포인트는 현금으로 환급될 수 없으며, 캐시와 적립금,
            포인트의 사용 기간이 만료되거나 이용계약이 종료되면 별도의 고지 없이
            자동소멸될 수 있습니다.
          </li>
          <li>
            회원은 캐시 및 적립금, 포인트를 제3자 또는 다른 아이디로 양도할 수
            없으며 유상으로 거래하거나 현금으로 전환할 수 없습니다.
          </li>
          <li>
            회사는 회원이 회사가 승인하지 않은 방법 또는 허위 정보 제공, 약관에
            위배되는 등의 부정한 방법으로 캐시 및 적립금, 포인트를 획득하거나
            부정한 목적이나 용도로 캐시 및 적립금, 포인트를 사용하는 경우 캐시
            및 적립금, 포인트의 사용을 제한하거나 회사가 정한 방법으로 회수할 수
            있으며, 캐시 및 적립금, 포인트를 사용한 구매신청을 취소할 수 있고,
            회원 자격을 정지할 수 있습니다.
          </li>
          <li>
            회원 탈퇴 시 전환/적립된 캐시 및 적립금, 포인트 중 미사용한 캐시 및
            적립금, 포인트는 즉시 소멸되며, 탈퇴 후 재가입하더라도 소멸된 캐시
            및 적립금, 포인트는 복구되지 아니합니다.
          </li>
          <li>서비스별 캐시 및 적립금, 포인트 상세내용은 아래와 같습니다.</li>
          <li>제주골프 서비스 : 제21조 내용과 동일</li>
          <li>
            예약 시 지급한 캐시는 즉시 적립되지 않고 라운드 완료 익일
            적립됩니다.
          </li>
          <li>
            위약기간에 예약 취소 시 해당 상품에 별도 규정된 위약 규정에 따라
            예약 시 사용한 캐시 전액 또는 일부가 반환이 되지 않을 수 있습니다.
          </li>
          <li>
            제주골프 홈페이지 및 온라인스토어 서비스 : 제 21조 내용과 동일
          </li>
        </ul>
        <ul>
          <li className="header">제 22조 [할인쿠폰]</li>
          <li>
            할인쿠폰은 회원에게 유상 또는 무상으로 발행되는 것으로 비회원에게는
            할인쿠폰이 발행되지 않고, 비회원은 할인쿠폰을 사용할 수 없습니다.
          </li>
          <li>
            회사는 회원이 할인쿠폰을 상품 등의 구매 시 적용할 수 있도록 그 사용
            대상, 사용 방법, 사용 기간, 구매가, 할인액 등을 정할 수 있습니다.
            할인쿠폰의 종류 또는 내용은 회사의 정책에 따라 달라질 수 있습니다.
            회사는 할인쿠폰의 사용 대상, 사용 방법, 사용 기간, 할인 금액 등을
            할인쿠폰 및/또는 서비스 화면에 별도로 표시하거나 통지합니다.
          </li>
          <li>
            할인쿠폰은 현금으로 환급될 수 없으며, 할인쿠폰의 사용 기간이
            만료되거나 구매 취소 시 또는 이용계약이 종료되면 소멸됩니다.
          </li>
          <li>
            회원은 할인쿠폰을 제3자에게 또는 다른 아이디로 양도할 수 없으며
            유상으로 거래하거나 현금으로 전환할 수 없습니다.
          </li>
          <li>
            회사는 회원이 회사가 승인하지 않은 방법으로 할인쿠폰을 획득하거나
            부정한 목적이나 용도로 할인쿠폰을 사용하는 경우 할인쿠폰의 사용을
            제한하거나 구매할인쿠폰을 사용한 구매신청을 취소하거나 회원 자격을
            정지할 수 있습니다.
          </li>
          <li>서비스별 “할인쿠폰” 상세내용은 아래와 같습니다.</li>
          <li>제주골프 예약서비스 : 제21조 내용과 동일</li>
          <li>제주골프 온라인스토어서비스 : 제 21조 내용과 동일</li>
        </ul>
        <ul>
          <li className="header">제 23조 [사용 가능한 결제 수단]</li>
          <li>
            회원은 회사에서 구매한 상품 등에 대한 대금지급을 다음 각 호의 하나로
            할 수 있습니다.(일부의 경우 다수를 결합하여 결제 가능)
          </li>
          <li>선불카드, 직불카드, 신용카드 등의 각종 카드 결제</li>
          <li>네이버페이, 카카오페이, 삼성페이</li>
          <li>모바일결제</li>
          <li>실시간 계좌입금, 가상계좌입금</li>
          <li>캐시 및 적립금 (회원의 경우에 한함)</li>
          <li>쿠폰 (회원의 경우에 한함)</li>
          <li>회사가 인정한 상품권에 의한 결제</li>
          <li>기타 회사가 추가 지정하는 결제 수단</li>
          <li>
            회사는 회원의 대금 지급에 법적, 기술적 문제가 발생하거나 회사가
            예견하지 못한 장애(은행 통신망 장애 등)가 발생하는 경우 회사의
            정책에 따라 회원에게 결제 수단의 변경을 요청하거나 결제를 보류 내지
            거부할 수 있습니다.
          </li>
          <li>
            구매대금의 결제와 관련하여 회원이 입력한 정보 및 그 정보와 관련하여
            발생한 책임과 불이익은 전적으로 회원이 부담합니다.
          </li>
        </ul>
        <ul>
          <li className="header">제 24조 [청약철회]</li>
          <li>
            회원은 상품 등의 구매에 관한 계약한 경우 구매일 또는
            이용가능일로부터 7일 이내에 별도의 수수료 없이 청약철회의 신청을 할
            수 있습니다. 다만, 회사가 회원에게 사전에 합의한 내용과 다르게
            서비스를 제공하거나 서비스를 제공하지 아니하는 경우 , 회원은 유료
            결제를 한 날로부 터 3개월 이내 또는 해당 사실을 알거나 알 수 있었던
            날로부터 30일 이내에 회사에 대한 서비스의 이용에 관한 청약을 철회할
            수 있습니다. 단, 상품 특성에 따라 별도의 위약 규정이 있는 상품의
            경우(골프장 이용 예약, 여행상품 등) 해당 상품별로 별도 규정된 위약
            규정을 고지하며 이용자가 구매 시에 이러한 위약 규정을 고지 받은
            경우에는 해당 규정이 약관보다 우선 적용됩니다.
          </li>
          <li>
            구매 후 7일이 지났거나 사용한 경우, 재화 등의 가치가 현저히 감소한
            경우, 기타 청약철회가 제한될 수 있는 사유가 발생한 경우에는 관련
            법령에 따라 청약철회가 제한될 수 있습니다. 이 경우 회사는 회원에
            대하여 해당 유료서비스 이용 시 고지하는 등 관련 법률에서 정한 바에
            따른 조치를 취하기로 합니다.
          </li>
          <li>
            회원은 다음 각 호의 어느 하나에 해당하는 경우에는 회사의 의사에
            반하여 제2항에 따른 청약철회를 할 수 없습니다.
          </li>
          <li>회원에게 책임이 있는 사유로 재화 등이 멸실 또는 훼손된 경우</li>
          <li>회원이 재화 등을 사용 또는 일부 소비한 경우</li>
          <li>시간이 지나 다시 판매하기 곤란할 경우</li>
          <li>복제 가능한 재화 등의 포장을 훼손한 경우</li>
          <li>그 밖에 거래의 안전을 위하여 법령으로 정하는 경우</li>
          <li>
            주문에 따라 개별적으로 생산되는 상품 등 그에 대하여 청약철회를
            인정할 경우 회사에게 회복할 수 없는 중대한 피해가 예상되는 경우로서,
            사전에 해당 거래에 대하여 별도로 그 사실을 고지하고 회원의
            서면(팝업창이나 체크박스에 동의가 표시되는 등 전자적 형태의 청약의
            의사표시에 동의의 의사가 표시된 경우를 포함)에 의한 동의를 받은 경우
          </li>
          <li>
            상품 등의 구매를 미성년자가 법정대리인의 동의 없이 진행한 경우,
            미성년자 또는 법정대리인은 회사에게 청약철회를 요청할 수 있으며,
            회사는 법정대리인임을 증명할 수 있는 서류를 요구할 수 있습니다.
          </li>
          <li>
            이용자는 제2항 및 제3항의 규정에도 불구하고 다음 각 호의 경우에는
            당해 상품 등을 공급받은 날부터 3개월 이내, 그 사실을 안 날 또는 알
            수 있었던 날부터 30일 이내에 청약철회를 할 수 있습니다.
          </li>
          <li>
            상품 등의 내용이 표시∙광고 내용과 다르거나 계약내용과 다르게 이행된
            때
          </li>
          <li>상품 등이 파손, 손상, 오염된 상태로 배송된 때</li>
          <li>
            「전자상거래 등에서의 소비자보호에 관한 법률」 제13조에 의하여
            표시∙광고하여야 할 사항을 표시하지 아니한 상태에서 상품 등의
            구매계약이 체결된 때
          </li>
          <li>
            회원은 구두 또는 서면(전자문서 포함)으로 청약철회를 할 수 있습니다.
          </li>
          <li>
            회사는 이용자가 상품 등의 교환 또는 반품신청을 한 날로부터 14일이
            지날 때까지 이미 수령한 상품 등을 회사에게 반환하지 아니 하거나
            전화, 이메일 등으로 연락되지 아니하는 경우에는 해당 이용자의 교환
            또는 반품신청은 효력을 상실합니다.
          </li>
        </ul>
        <ul>
          <li className="header">제 25조 [청약철회 효과]</li>
          <li>
            회사는 청약철회 또는 계약해지의 의사표시를 수령한 날(단, 상품 등이
            배송된 경우에는 판매자가 상품 등을 반환 받은 날)로부터 3영업일
            이내에 이미 지급받은 상품 등의 대금을 이용자가 상품 등의 대금을
            지급한 결제수단을 통하여 환급합니다.
          </li>
          <li>
            신용카드 또는 전자화폐 등의 결제수단으로 상품 등의 결제가 이루어진
            경우 환불은 이용자가 결제한 수단과 동일한 수단으로 환불하는 것을
            원칙으로 합니다. 동일한 수단으로 환불하는 것이 불가능한 경우 다음
            각호 중 1의 방법으로 처리합니다.
          </li>
          <li>이용자에게 개별 연락하여 확인 후 계좌입금 또는 적립금 환불</li>
          <li>
            환불예정금액으로 표시 및 보관처리하고, 추후 이용자의 요청에 따라
            계좌입금 또는 적립금 환불
          </li>
          <li>
            회사는 청약철회시 회원의 결제 건에 대하여 결제업자 또는 앱마켓에
            대금지급의 정지, 취소 등 「전자상거래등에서의 소비자 보호에 관한
            법률」에 따른 필요한 조치를 취합니다.
          </li>
        </ul>
        <ul>
          <li className="header">제 26조 [교환]</li>
          <li>
            이용자는 회사의 상품 등 발송 시로부터 배송완료일 후 7일이내까지(단,
            관련법령에서 달리 정하는 경우 해당 기준에 따라) 관련법령에 의거하여
            교환을 신청할 수 있습니다. 단, 스토어에서 발송 준비중으로
            표시되더라도 실제 상품 등의 발송이 이루어진 경우 본 조의 내용을
            적용하여 교환 절차로 진행합니다.
          </li>
          <li>
            교환은 동일한 상품 및 동일한 옵션인 경우에만 가능합니다. 이 외의
            경우에는 청약철회 후 재구매절차를 진행하여야 합니다.
          </li>
          <li>
            교환신청을 하더라도 회사에게 교환할 상품등의 재고가 없는 경우에는
            교환이 불가능할 수 있으며, 이 경우 해당 교환신청은 제24조를 준용하여
            청약철회로 처리됩니다.
          </li>
          <li>
            교환에 소요되는 배송비 및 기타 비용은 교환에 대한 귀책사유가 있는
            자에게 일반적으로 귀속됩니다(예시: 단순변심의 경우 이용자부담,
            상품하자의 경우 회사부담 등). 단 당사자간에 별도의 약정이 있었던
            때에는 그 약정에 따라 부담합니다.
          </li>
          <li>
            교환신청 시 교환사유에 관하여 회사에게 정확히 통보(구두 또는
            서면으로)하지 않고 상품등을 반환하거나 이용자 부담의 교환배송비를
            지불하지 않는 등의 사유가 발생하면 교환처리가 지연될 수 있습니다.
          </li>
          <li>
            기타 교환에 관하여 본 조에 명시되지 아니한 사항은 제24조에 의합니다.
          </li>
        </ul>
        <ul>
          <li className="header">제 27조 [과오금 및 환불]</li>
          <li>
            과오금이 발생한 경우 회사는 이용자가 상품 등의 대금을 지급한
            결제수단과 동일한 방법으로 과오금을 환불합니다. 단, 동일한 방법으로
            환불이 불가능할 때에는 이를 고지하고 제25조 제2항에 따릅니다.
          </li>
          <li>
            회원에게 책임 있는 사유로 과오금이 발생한 경우 환불에 소요되는
            수수료 등은 회원이 부담합니다.
          </li>
          <li>
            회사에게 책임 있는 사유로 과오금이 발생한 경우 회사는 계약비용,
            수수료 등에 관계없이 과오금 전액을 환불합니다.
          </li>
        </ul>
        <ul>
          <li className="header">제 28조 [손해배상]</li>
          <li>
            회사는 서비스에서 무료로 제공하는 서비스의 이용과 관련하여
            개인정보보호정책에서 정하는 내용에 해당하지 않는 사항에 대하여는
            어떠한 손해도 책임을 지지 않습니다.
          </li>
        </ul>
        <ul>
          <li className="header">제 29조 [면책조항]</li>
          <li>
            천재지변, 전쟁 및 기타 이에 준하는 불가항력으로 인하여 서비스를
            제공할 수 없는 경우에는 서비스 제공에 대한 회사의 책임은 면제됩니다.
          </li>
          <li>
            기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로 제공하지
            아니하는 등 회사의 책임없는 사유로 손해가 발생한 경우 회사의 책임이
            면제됩니다.
          </li>
          <li>
            서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한
            손해에 대한 회사의 책임은 면제됩니다.
          </li>
          <li>
            회사는 회원의 귀책사유로 인한 서비스 이용의 장애 또는 손해에 대하여
            책임을 지지 않습니다.
          </li>
          <li>
            회사는 이용자의 컴퓨터 오류에 의해 손해가 발생한 경우, 또는 회원이
            신상정보 및 이메일 주소 또는 연락가능한 수단을 부실하게 기재하여
            손해가 발생한 경우 책임을 지지 않습니다.
          </li>
          <li>
            회사는 회원이 서비스를 이용하여 기대하는 수익을 얻지 못하거나 상실한
            것에 대하여 책임을 지지 않습니다.
          </li>
          <li>
            회사는 회원이 서비스를 이용하면서 얻은 자료로 인한 손해에 대하여
            책임을 지지 않습니다.
          </li>
          <li>
            회사는 회원이 서비스를 이용하며 타 회원으로 인해 입게 되는 정신적
            피해에 대하여 보상할 책임을 지지 않습니다.
          </li>
          <li>
            회사는 회원이 서비스에 게재한 각종 정보, 자료, 사실의 신뢰도, 정확성
            등 내용에 대하여 책임을 지지 않습니다.
          </li>
          <li>
            회사는 이용자 상호간 및 이용자와 제 3자 상호 간에 서비스를 매개로
            발생한 분쟁에 대해 개입할 의무가 없으며, 이로 인한 손해를 배상할
            책임도 없습니다.
          </li>
          <li>
            회사에서 회원에게 무료로 제공하는 서비스의 이용과 관련해서는 어떠한
            손해도 책임을 지지 않습니다.
          </li>
        </ul>
        <ul>
          <li className="header">제 30조 [관할 및 준거법]</li>
          <li>
            약관에 명시되지 않은 사항은 전기통신사업법 등 관계법령과 상관습에
            따릅니다.
          </li>
          <li>
            회사의 정액 서비스 회원 및 기타 유료 서비스 이용 회원의 경우 회사가
            별도로 정한 약관 및 정책에 따릅니다.
          </li>
          <li>
            서비스 이용으로 발생한 분쟁에 대해 소송이 제기되는 경우 회사의 본사
            소재지를 관할하는 제주지방법원을 제 1심의 관할 법원으로 합니다.
          </li>
        </ul>
        <ul>
          <li className="header">【 부 칙 】</li>
          <li className="section">제 1조 [시행일]</li>
          <li>본 약관은 2024년 03월 01일부터 적용됩니다.</li>
        </ul>
      </PrivacyBody>
    </PrivacyWrap>
  );
};

const PrivacyWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;

  padding: 1em;
  z-index: 111111111;
  overflow-y: scroll;
`;

const PrivacyBody = styled.div`
  position: relative;
  box-shadow: 0px 0px 0px 10px #29000000;

  p {
    font-size: 1.5em;
  }

  ul {
    margin: 0;
    padding: 0;
  }
  li {
    padding: 0;
    list-style: none;
    margin: 0;
    font-size: 0.5em;
    word-break: keep-all;
    padding-bottom: 0.5em;

    &.header {
      font-weight: 600;
      font-size: 0.8em;
      padding-top: 1em;
      padding-bottom: 1em;
    }

    &.section {
      font-weight: 500;
      font-size: 0.65em;
      padding: 0.65em 0;
    }
  }
`;

const PrivacyTable = styled.table`
  border: 1px solid black;
  border-collapse: collapse;

  text-align: center;

  td {
    padding: 0.5em;
    border: 1px solid black;
    border-collapse: collapse;
  }
`;
export default AppUserPrivacy;
