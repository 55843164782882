export const BREAK_POINT = {
  //common
  desktop: 1024,
  mobile: 600,
  tablet: 768,
  mobile_lg: 425,
  mobile_m: 375,
  mobile_s: 320,

  // desktop setion1 img crashed Size
  section1: {
    mb_crash_size: 1845, // main_banner crashed size
    main_contents: 1271,
  },
  section2: {
    first_crashed_size: 1750,
    second_crashed_size: 1450,
    third_crashed_size: 1281,
    fourth_crahsed_size: 1200,
  },
  section3: {
    first_crashed_size: 1200,
    second_crashed_size: 1148,
    third_crahsed_size: 1092,
    step4: {
      bp_first: 1270,
      bp_second: 1070,
      min_margin: 10,
    },
  },
  section4: {
    bp_first: 1650,
    bp_second: 1515,
  },

  section5: {
    bp_first: 1490,
    bp_second: 1170,
    bp_third: 930,
  },

  footer: {
    bp_first: 1520,
    bp_second: 1192,
    bp_third: 1020,
  },
};
