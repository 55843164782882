import { S5BgContent, S5Button, Section5Wrap } from "./section5Style";
import s5bg from "../assets/img/15.png";
import {
  FCol,
  FInfoContents,
  FInfoList,
  FInfoListItem,
  FInfoListItemSpan,
  FInputGroup,
  FInputLabel,
  FInputText,
  FSendBtnArea,
  FSendButton,
  FSubTitle,
  FTitle,
  FWrap,
} from "./footerMobileStyle";
import { HiMiniPlay } from "react-icons/hi2";
import { HiOutlineMail } from "react-icons/hi";
import fb from "../assets/img/facebook.png";
import insta from "../assets/img/insta.png";
import yt from "../assets/img/youyube.png";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { emailReg } from "./common/validationStd";
import img5 from "../assets/img/jejugolf_bottom_img.jpg";

const Section5Mobile = ({ curIndex }) => {
  const s5Ref = useRef(null);
  const fRef = useRef(null);
  const NOT_YET_TEXT = "4월 중 오픈입니다";

  useEffect(() => {
    if (curIndex == 2 && s5Ref.current != null) {
      s5Ref.current?.scrollIntoView({ behavior: "smooth" });
    }
    if (curIndex == 3 && fRef.current != null) {
      fRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [curIndex]);

  const [gToggle, setGToogle] = useState(false);
  const [aToggle, setAToggle] = useState(false);
  useEffect(() => {
    if (gToggle) {
      const interval = setInterval(() => {
        setGToogle(false);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [gToggle]);

  useEffect(() => {
    if (aToggle) {
      const interval = setInterval(() => {
        setAToggle(false);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [aToggle]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { erorrs },
  } = useForm();
  const onSubmit = async (data) => {};

  return (
    <>
      <Section5Wrap ref={s5Ref}>
        <S5BgContent>
          <img
            src={img5}
            alt={"제주골프 앱 출시 bottom"}
            style={{
              display: "block",
              width: "100%",
            }}
          />
          {/* <S5Button
            type="button"
            $bgColor={"#006F49"}
            $toggle={gToggle}
            onClick={() => {
              !gToggle && setGToogle(true);
            }}
          >
            <span className={"untoggle"}>Google Play</span>
            <span className={"toggle"}>{NOT_YET_TEXT}</span>
          </S5Button>
          <S5Button
            type="button"
            $bgColor={"#FFBA00"}
            $toggle={aToggle}
            onClick={() => !aToggle && setAToggle(true)}
          >
            <span className={"untoggle"}>App Store</span>
            <span className={"toggle"}>{NOT_YET_TEXT}</span>
          </S5Button> */}
        </S5BgContent>
      </Section5Wrap>
      <FWrap style={{ paddingBottom: "60px" }} ref={fRef}>
        <FCol>
          <FInfoContents>
            <FTitle>Contact Us</FTitle>
            <FSubTitle>
              필수 정보와 문의 내용을 간략하게 입력해주세요.
            </FSubTitle>
          </FInfoContents>
          <FInputGroup>
            <FInputLabel>NAME</FInputLabel>
            <FInputText
              placeholder="Your Name"
              {...register("name", { required: true })}
            />
          </FInputGroup>
          <FInputGroup>
            <FInputLabel>EMAIL</FInputLabel>
            <FInputText
              placeholder="Your Email"
              {...register("email", { required: true, pattern: emailReg })}
            />
          </FInputGroup>
          <FInputGroup>
            <FInputLabel>MESSAGE</FInputLabel>
            <FInputText
              placeholder="Say, Hi! Jeju"
              {...register("message", { required: true })}
            />
          </FInputGroup>
          <FSendBtnArea>
            <FSendButton type={"submit"}>
              SEND <HiMiniPlay />
            </FSendButton>
          </FSendBtnArea>
          <FInfoList>
            <FInfoListItem>
              <HiOutlineMail size={17} color={"#626262"} />
              <FInfoListItemSpan>golfrgo@jejugolf.kr</FInfoListItemSpan>
            </FInfoListItem>
            <FInfoListItem style={{ paddingTop: "30px" }}>
              <img src={insta} style={{ width: "50px" }} />
              <img src={yt} style={{ width: "50px" }} />
              <img src={fb} style={{ width: "50px" }} />
            </FInfoListItem>
          </FInfoList>
        </FCol>
      </FWrap>
    </>
  );
};

export default Section5Mobile;
