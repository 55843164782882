import { useState } from "react";
import { HiMenu } from "react-icons/hi";
import styled from "styled-components";
import logo from "../../assets/img/jejugolf_logo_final.png";
import { useNavigate } from "react-router-dom";

const HeaderMobile = ({ refChange }) => {
  const navi = useNavigate();

  const [toggle, setToggle] = useState(false);
  const onClick = () => {
    setToggle(!toggle);
  };
  const bgClick = (e) => {
    console.log(e);
    console.log(e.target);
    if (e.target?.className == "behindbg") {
      setToggle(false);
    }
  };

  const onPageMove = (target, num) => {
    setToggle(false);
    navi(target);
    refChange(num);
  };
  return (
    <HMWrap $toggle={toggle}>
      <div className={"prev"}>
        <HiMenu onClick={onClick} />
      </div>
      <div className={"behind"}>
        <div className={"behindbg"} onClick={bgClick}></div>
        <ul>
          <li className={"liHeader"}>
            <img src={logo} />
          </li>
          <li onClick={() => onPageMove("/", 1)}>상세정보</li>
          <li onClick={() => onPageMove("/", 2)}>다운로드</li>
          <li onClick={() => onPageMove("/", 3)}>안내</li>
          {/* <li onClick={() => onPageMove("/event", 0)}>이벤트</li> */}
        </ul>
      </div>
    </HMWrap>
  );
};

const HMWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  z-index: 111111;

  .prev {
  }
  .behind {
    font-size: 18px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: transparent;
    display: flex;
    justify-content: end;
    /* z-index: 111111; */
    transition: transform 0.3s ease-in-out;
    transform: translatex(${(props) => (props.$toggle ? "0" : "100%")});

    .behindbg {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 111113;
      background-color: #00000083;
    }

    ul {
      background-color: white;
      width: 40%;
      z-index: 11111111;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: start;
      list-style: none;
      > li {
        font-size: 0.7em;
        letter-spacing: -0.75px;
        font-weight: bold;
        width: 100%;
        margin: 5px 0;
        padding: 10px 10px;
        border-top: 1px solid #ebebeb;

        &.liHeader {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px 0 10px 0;
          margin: 0;
          border: none;
        }
        > img {
          width: 80px;
          display: block;
        }
      }
    }
  }
`;

export default HeaderMobile;
