import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  S1BVideo,
  S1BtnArea,
  S1Button,
  S1Contents,
  S1MBgWrap,
  S1SubTitle,
  S1Title,
  S1Wrap,
} from "./section1MobileStyle";
import bg1 from "../assets/img/1.png";
import YouTube from "react-youtube";

const Section1Mobile = () => {
  const [vloading, setVloading] = useState(true);
  const [gToggle, setGToggle] = useState(false);
  const [aToggle, setAToogle] = useState(false);

  useEffect(() => {
    if (gToggle) {
      const interval = setInterval(() => setGToggle(false), 1000);
      return () => clearInterval(interval);
    }
  }, [gToggle]);
  useEffect(() => {
    if (aToggle) {
      const interval = setInterval(() => setAToogle(false), 1000);
      return () => clearInterval(interval);
    }
  }, [aToggle]);

  return (
    <S1Wrap $bg={bg1}>
      <S1MBgWrap>
        <S1BVideo
          src={"/videos/bgvideo.mp4"}
          // width={"100%"}
          height={"490px"}
          loop
          playsInline
          autoPlay
          muted
        />
      </S1MBgWrap>

      <S1Contents>
        <S1Title>
          제주골프를 100% 즐기는 법
          <br /> 제주골프 어플로 한번에!
        </S1Title>
        <S1SubTitle>
          예약, 조인, 제주골프 아마추어 리그전, 캐디백 딜리버리, 스코어관리,
          렌탈서비스, 숙박, 해외투어, 중고클럽마켓, 현장레슨 등 제주골프와 함께
          케어 받으세요.
        </S1SubTitle>
        <S1BtnArea>
          <S1Button
            type={"button"}
            $isOutline={false}
            className={"installBtn"}
            $toggle={gToggle}
            onClick={() => {
              !gToggle && setGToggle(true);
            }}
          >
            <span className={"untoggle"}>Google Play</span>
            <span className={"toggle"}>4월 중 오픈</span>
          </S1Button>
          <S1Button
            type={"button"}
            $isOutline={true}
            className={"installBtn"}
            $toggle={aToggle}
            onClick={() => {
              !aToggle && setAToogle(true);
            }}
          >
            <span className={"untoggle"}>App Store</span>
            <span className={"toggle"}>4월 중 오픈</span>
          </S1Button>
        </S1BtnArea>
      </S1Contents>
    </S1Wrap>
  );
};

export default Section1Mobile;
